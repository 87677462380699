import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type SkillCategoryEditFormValues = {
    skillCategoryName: string
};

export const useSkillCategoryEditForm = (initialValues: SkillCategoryEditFormValues) => {
    const schema = skillCategoryValidationSchema();

    return useForm<SkillCategoryEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: initialValues
    });
};

const skillCategoryValidationSchema = () => {
    return yup
        .object({
            skillCategoryName: yup.string().required("Skill category name is required."),
        })
        .required();
};