import { Box, useMediaQuery, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import ProposalMilestonesTable from "./ProposalMilestonesTable";
import { useAppPaths, useProposalId } from "../../Routes";
import DeletableChips from "../DeletableChips";
import { useNavigate } from "react-router-dom";
import { useProposalMilestones } from "../../api/proposalMilestones";
import { useProposal } from "../../api/proposal";
import { useOrganizationConfig } from "../../api/organization";

const ProposalMilestonesTablePage = () => {
    const theme = useTheme();
    const milestones = useProposalMilestones();
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const filteredProposalId = useProposalId();
    const { proposal } = useProposal(filteredProposalId);
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const { gigTerminology } = useOrganizationConfig();

    const filterChips = proposal?.gig.title && (
        <DeletableChips
            chips={[
                {
                    label: `Filtered by ${gigTerminology.toLowerCase()}: ${proposal.gig.title}`,
                    onDelete: () => {
                        navigate(appPaths.proposals.index);
                    },
                    visible: filteredProposalId ? true : false
                }
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
                maxWidth: "18rem",
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1
                },
            }}>
                {showFilterChipsInline && !milestones.isLoading && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
            </Box>
            {!showFilterChipsInline && !milestones.isLoading && (
                <Box>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <ProposalMilestonesTable
                    filteredProposalId={filteredProposalId}
                    milestones={milestones}
                />
            </Box>
        </>
    );
};

export default ProposalMilestonesTablePage;