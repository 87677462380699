import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { ApiProposalMilestoneSummaryDto } from "./models/api/proposalMilestone";

export type UseProposalMilestonesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiProposalMilestoneSummaryDto>>, "data"> & {
        proposalMilestones: ApiProposalMilestoneSummaryDto[]
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        proposalId?: string
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setProposalId: (proposalId?: string) => void
    };

export const useProposalMilestones = (initialPageSize = 10): UseProposalMilestonesReturn => {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [proposalId, setProposalId] = useState<string | undefined>();

    const url = "api/proposal-milestones?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `proposalId=${proposalId || ""}`;
        
    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiProposalMilestoneSummaryDto>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        proposalMilestones: apiCall.data?.items || [],
        setPageIndex,
        setPageSize,
        setProposalId
    };
};