import { useParams } from "react-router-dom";

import { GigApiFetcherResponse } from "../api/common/fetching";
import { useUser } from "../api/user";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { UserEditForm, UserEditFormValues } from "../components/UserEditForm";
import { successToast } from "../toast";

const UserEditPage = () => {
    const { userId } = useParams<{ userId: string }>();
    const user = useUser(userId as string);
    
    const handleSubmit = async (values: UserEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await user.updateUser({
            ...values,
            isAdmin: JSON.parse(values.isAdmin),
        });
        if (response.success) {
            successToast("User successfully edited");
        }

        return response;
    };

    const handleArchive = async () => {
        const response = await user.archiveUser();

        if (response.success) {
            successToast("User successfully archived.");
        }

        return response;
    };

    const handleUnarchive = async () => {
        const response = await user.unarchiveUser();

        if (response.success) {
            successToast("User successfully unarchived.");
        }

        return response;
    };

    const handleDelete = async () => {
        const response = await user.deleteUser();

        if (response.success) {
            successToast("User successfully deleted.");
        }

        return response;
    };

    if (user.isLoading) return <Loader />;

    return (
        <FormContainer>
            {user && (
                <UserEditForm
                    onSubmit={handleSubmit}
                    initialValues={user.user}
                    onArchive={handleArchive}
                    onUnarchive={handleUnarchive}
                    onDelete={handleDelete}
                    isArchiving={user.isArchivingUser}
                    isUnarchiving={user.isUnarchivingUser}
                    isDeleting={user.isDeletingUser}
                    isSubmitting={user.isUpdatingUser}
                />
            )}
        </FormContainer>
    );
};

export default UserEditPage;
