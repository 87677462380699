import { ApiGigSummary, ApiUpdateGigDto, ApiCreateGigDto, ApiEditableGigFields } from "./api/gig";

type AppGig<T> = Omit<T, "startDate" | "deadlineDate"> & {
    startDate: Date
    deadlineDate: Date | null
};

export type EditableGigFields = AppGig<ApiEditableGigFields>

export type CreateGigDto =  AppGig<ApiCreateGigDto>
export type UpdateGigDto = AppGig<ApiUpdateGigDto>

export type GigSummary =  AppGig<ApiGigSummary> & {
    expectedDurationLabel: string
    initialStageLabel: string
}

export const gigStatuses = {
    draft: 0,
    posted: 1,
    hired: 2,
    cancelled: 3,
    completed: 4,
};

export const gigStatusOptions = [
    { label: "Draft", value: 0 },
    { label: "Posted", value: 1 },
    { label: "Hired", value: 2 },
    { label: "Cancelled", value: 3 },
    { label: "Completed", value: 4 },
];

export const expectedDurationOptions = [
    { label: "Less than a week", value: 0 },
    { label: "Less than a month", value: 1 },
    { label: "1 month", value: 2 },
    { label: "2-3 months", value: 3 },
    { label: "3-6 months", value: 4 },
    { label: "More than 6 months", value: 5 },
];

export const initialStageOptions = [
    { label: "Not started", value: 0 },
    { label: "Just kicked off", value: 1 },
    { label: "Partly complete", value: 2 },
];