import Loader from "../../components/Loader";
import DashboardClientsSection from "./DashboardClientsSection";
import DashboardGigsSection from "./DashboardGigsSection";
import DashboardProposalsSection from "./DashboardProposalsSection";
import DashboardSkillsSection from "./DashboardSkillsSection";
import DashboardTalentsSection from "./DashboardTalentsSection";
import DashboardUsersSection from "./DashboardUsersSection";
import DashboardDisputesSection from "./DashboardDisputesSection";
import DashboardReportsSection from "./DashboardReportsSection";
import { useClientStatistics } from "../../api/dashboardStatistics/clientStatistics";
import { useProposalStatistics } from "../../api/dashboardStatistics/proposalStatistics";
import { useTalentStatistics } from "../../api/dashboardStatistics/talentStatistics";
import { useSkillStatistics } from "../../api/dashboardStatistics/skillStatistics";
import { useDisputeStatistics } from "../../api/dashboardStatistics/disputeStatistics";
import { useReportStatistics } from "../../api/dashboardStatistics/reportStatistics";
import { useGigStatistics } from "../../api/dashboardStatistics/gigStatistics";
import { useUserStatistics } from "../../api/dashboardStatistics/userStatistics";
import { useOrganizationConfig } from "../../api/organization";

const DashboardOverview = () => {
    const users = useUserStatistics();
    const clients = useClientStatistics();
    const talents = useTalentStatistics();
    const gigs = useGigStatistics();
    const proposals = useProposalStatistics();
    const skills = useSkillStatistics();
    const disputes = useDisputeStatistics();
    const reports = useReportStatistics();

    const { organizationConfig } = useOrganizationConfig();

    const isLoading = 
        users.isLoading ||
        clients.isLoading || 
        talents.isLoading ||
        gigs.isLoading ||
        proposals.isLoading ||
        skills.isLoading ||
        disputes.isLoading ||
        reports.isLoading;

    if (isLoading) return <Loader />;

    return (
        <div className="space-y-6">
            <DashboardUsersSection statistics={users.statistics} />
            <DashboardClientsSection statistics={clients.statistics} />
            <DashboardTalentsSection statistics={talents.statistics} />
            <DashboardGigsSection statistics={gigs.statistics} />
            <DashboardProposalsSection statistics={proposals.statistics} />
            <DashboardSkillsSection statistics={skills.statistics} />
            {organizationConfig?.isDisputesEnabled && <DashboardDisputesSection statistics={disputes.statistics} />}
            {organizationConfig?.isReportsEnabled && <DashboardReportsSection statistics={reports.statistics} />}
        </div>
    );
};

export default DashboardOverview;