import { useEffect, useState } from "react";
import { mapFromApiProposalMilestoneDetails, mapToApiUpdateProposalMilestone } from "../mappers/proposalMilestone";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiProposalMilestoneDetailsDto, ApiUpdateProposalMilestoneDto } from "./models/api/proposalMilestone";
import { ProposalMilestoneDetailsDto, UpdateProposalMilestoneDto } from "./models/proposalMilestone";

export const useUpdateProposalMilestone = () => useAuthenticatedGigApiFetcher<ApiUpdateProposalMilestoneDto, void, { proposalMilestoneId: string, dto: UpdateProposalMilestoneDto }>("PUT", 
    ({ proposalMilestoneId, dto }) => ({
        path: `api/proposal-milestones/${proposalMilestoneId}`,
        body: mapToApiUpdateProposalMilestone(dto)
    })
);

export type UseProposalMilestoneReturn<TProposalMilestoneId extends string | undefined> = TProposalMilestoneId extends undefined ? 
    { proposalMilestone: undefined } : 
    {
        proposalMilestone?: ProposalMilestoneDetailsDto
        updateProposalMilestone: (dto: UpdateProposalMilestoneDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingProposalMilestone: boolean
        isLoading: boolean
    }

export const useProposalMilestone = <TProposalMilestoneId extends string | undefined>(proposalMilestoneId: TProposalMilestoneId): UseProposalMilestoneReturn<TProposalMilestoneId> => {
    const [proposalMilestone, setProposalMilestone] = useState<ProposalMilestoneDetailsDto | undefined>();
    const [updateProposalMilestone, isUpdatingProposalMilestone]= useUpdateProposalMilestone();

    const url = `api/proposal-milestones/${proposalMilestoneId}`;

    const apiCall = useGiggedApiSWR<ApiProposalMilestoneDetailsDto>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setProposalMilestone(undefined);
            return;
        }
        
        setProposalMilestone(mapFromApiProposalMilestoneDetails(apiCall.data));
    }, [apiCall.data]);

    if (proposalMilestoneId === undefined) return { proposalMilestone: undefined } as UseProposalMilestoneReturn<TProposalMilestoneId>;

    return {
        ...apiCall,
        proposalMilestone, 
        updateProposalMilestone: async (dto: UpdateProposalMilestoneDto) => {
            const response = await updateProposalMilestone({ proposalMilestoneId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        isUpdatingProposalMilestone, 
        isLoading: apiCall.isLoading
    } as UseProposalMilestoneReturn<TProposalMilestoneId>;
};