import { useState } from "react";
import { Box, Divider, ListItemText, MenuItem, MenuList, useMediaQuery, useTheme } from "@mui/material";
import { useDebounce } from "use-debounce";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import SearchBox from "../SearchBox";
import TalentWithdrawalsTable from "./TalentWithdrawalsTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useOrganizationConfig } from "../../api/organization";
import { talentWithdrawalStatus } from "../../api/models/talentWithdrawal";
import DeletableChips from "../DeletableChips";
import { IconButtonMenu, MenuCheckboxGroup } from "../Menu";

const TalentWithdrawalsTableWithSearch = () => {
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const [filterByWithdrawalStatusIds, setFilterByWithdrawalStatusIds] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const organizationConfig = useOrganizationConfig();

    const removeFilterByWithdrawalStatusId = (withdrawalStatusId: number) => {
        setFilterByWithdrawalStatusIds(filterByWithdrawalStatusIds.filter(id => id !== withdrawalStatusId));
    };

    const addFilterByWithdrawalStatusId = (withdrawalStatusId: number) => {
        const index = filterByWithdrawalStatusIds.indexOf(withdrawalStatusId);

        if (index !== -1) {
            console.warn("Tried to add gig status filter but gig status was already being filtered", withdrawalStatusId);
            return;
        }

        setFilterByWithdrawalStatusIds([...filterByWithdrawalStatusIds, withdrawalStatusId]);
    };

    const isWithdrawalStatusIdFiltered = (withdrawalStatusId: number): boolean => {
        return filterByWithdrawalStatusIds.find(id => id === withdrawalStatusId) !== undefined;
    };

    const filterChips = (
        <DeletableChips
            chips={[
                {
                    label: "Pending",
                    onDelete: () => removeFilterByWithdrawalStatusId(talentWithdrawalStatus.pending),
                    visible: isWithdrawalStatusIdFiltered(talentWithdrawalStatus.pending),
                },
                {
                    label: "Complete",
                    onDelete: () => removeFilterByWithdrawalStatusId(talentWithdrawalStatus.complete),
                    visible: isWithdrawalStatusIdFiltered(talentWithdrawalStatus.complete),
                },
                {
                    label: "Rejected / Cancelled",
                    onDelete: () => removeFilterByWithdrawalStatusId(talentWithdrawalStatus.rejectedOrCancelled),
                    visible: isWithdrawalStatusIdFiltered(talentWithdrawalStatus.rejectedOrCancelled),
                },
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? `Search by ${organizationConfig.talentTerminology} First, Last or Trading name` : "Search withdrawals"}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={showFilterChipsInline ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
                {showFilterChipsInline && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
                
                <IconButtonMenu
                    id="filter-gigs"
                    icon={isLargeScreen ? <FilterIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" /> : <MoreVertIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />}
                >
                    <MenuList sx={{ width: "17rem", padding: 0}}>
                        <MenuCheckboxGroup
                            label="Status"
                            options={[
                                {
                                    label: "Pending",
                                    value: isWithdrawalStatusIdFiltered(talentWithdrawalStatus.pending),
                                    onChange: () => isWithdrawalStatusIdFiltered(talentWithdrawalStatus.pending)
                                        ? removeFilterByWithdrawalStatusId(talentWithdrawalStatus.pending)
                                        : addFilterByWithdrawalStatusId(talentWithdrawalStatus.pending)
                                },
                                {
                                    label: "Complete",
                                    value: isWithdrawalStatusIdFiltered(talentWithdrawalStatus.complete),
                                    onChange: () => isWithdrawalStatusIdFiltered(talentWithdrawalStatus.complete)
                                        ? removeFilterByWithdrawalStatusId(talentWithdrawalStatus.complete)
                                        : addFilterByWithdrawalStatusId(talentWithdrawalStatus.complete)
                                },
                                {
                                    label: "Rejected / Cancelled",
                                    value: isWithdrawalStatusIdFiltered(talentWithdrawalStatus.rejectedOrCancelled),
                                    onChange: () => isWithdrawalStatusIdFiltered(talentWithdrawalStatus.rejectedOrCancelled)
                                        ? removeFilterByWithdrawalStatusId(talentWithdrawalStatus.rejectedOrCancelled)
                                        : addFilterByWithdrawalStatusId(talentWithdrawalStatus.rejectedOrCancelled)
                                },
                            ]}
                        />
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setFilterByWithdrawalStatusIds([]);
                            }}
                        >
                            <ListItemText sx={{ textAlign: "right" }}>
                                Clear filters
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </IconButtonMenu>
            </Box>
            <Box sx={{ marginTop: "1.5rem" }}>
                <TalentWithdrawalsTable
                    searchTerm={debounceSearchTerm}
                    withdrawalStatusIds={filterByWithdrawalStatusIds}
                />
            </Box>
        </>
    );
};

export default TalentWithdrawalsTableWithSearch;