import { Typography } from "@mui/material";
import { useOrganizationConfig } from "../../api/organization";
import MoneyDisplay from "../MoneyDisplay";

export type DailyRateDisplayProps = {
    dailyRate: number
}

const DailyRateDisplay = ({
    dailyRate
}: DailyRateDisplayProps) => {
    const { gigTerminology } = useOrganizationConfig();

    return (
        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
            {gigTerminology} daily rate: <Typography component="span"><MoneyDisplay value={dailyRate} /></Typography>
        </Typography>
    );
};

export default DailyRateDisplay;