import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type TalentEditFormValues = {
    organizationAvailability?: string | null
    agencyId?: string | null
};

export const useEditTalentForm = (initialValues?: TalentEditFormValues) => {
    const schema = talentValidationSchema();

    return useForm<TalentEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: initialValues
    });
};

const talentValidationSchema = () => {
    return yup
        .object({
            organizationAvailability: yup.string().max(40, "Availability cannot be longer than 40 characters.").nullable(),
            agencyId: yup.string()
        })
        .required();
};