import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { FormProvider } from "react-hook-form";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../ConfirmDialogBox";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { RejectProposalFormValues, useRejectProposalForm } from "./RejectProposalFormValues";
import FormTextAreaInput from "../FormTextAreaInput";

export type RejectProposalFormProps = {
    handleCloseDialog: () => void
    onSubmit: (dto: RejectProposalFormValues) => Promise<GigApiFetcherResponse<void>>
    loading: boolean
}

const RejectProposalForm = ({
    handleCloseDialog,
    onSubmit,
    loading
}: RejectProposalFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useRejectProposalForm();
    const { handleSubmit, formState: { dirtyFields } } = methods;
    const isLargeScreen = useIsLargeScreen();
    const isDirtyAlt = Object.keys(dirtyFields).length > 0;

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCloseDialog();
        }
    });

    const handleSubmitForm = async (values: RejectProposalFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);

        return response;
    };

    const handleCloseResolveFormDialog = () => {
        if (isDirtyAlt) {
            openCancelDialog();
        } else {
            handleCloseDialog();
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <Box>
                    <form
                        noValidate
                        onSubmit={handleSubmit(handleSubmitForm)}>
                        <Box className="space-y-4">
                            <Box className="space-y-2">
                                <FormInputLabel required>Reason</FormInputLabel>
                                <FormTextAreaInput
                                    required
                                    name="rejectedReason"
                                    disabled={loading}
                                    rows={2}
                                />
                            </Box>
                            <FormErrors messages={submissionError?.userMessages} />
                        </Box>
                        <Box className="flex flex-col-reverse md:flex-row justify-end items-center md:space-x-4 mt-8">
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={handleCloseResolveFormDialog}
                                sx={!isLargeScreen ? { width: "100%" } : { width: "auto" }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={!isDirtyAlt}
                                loading={loading}
                            >
                                Reject
                            </LoadingButton>
                        </Box>
                    </form>
                </Box >
            </FormProvider >
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default RejectProposalForm;