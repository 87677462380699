import { ApiProposalMilestoneDetailsDto, ApiProposalMilestoneSummaryDto, ApiUpdateProposalMilestoneDto } from "../api/models/api/proposalMilestone";
import { ProposalMilestoneDetailsDto, ProposalMilestoneSummaryDto, UpdateProposalMilestoneDto } from "../api/models/proposalMilestone";

export const mapFromApiProposalMilestoneSummary = (apiDto: ApiProposalMilestoneSummaryDto): ProposalMilestoneSummaryDto => ({
    ...apiDto,
    clientPaymentCollectedDate: apiDto.clientPaymentCollectedDate ? new Date(apiDto.clientPaymentCollectedDate) : null,
    dateCompleted: apiDto.dateCompleted ? new Date(apiDto.dateCompleted) : null,
});

export const mapFromApiProposalMilestoneDetails = (apiDto: ApiProposalMilestoneDetailsDto): ProposalMilestoneDetailsDto => ({
    ...apiDto,
    clientPaymentCollectedDate: apiDto.clientPaymentCollectedDate ? new Date(apiDto.clientPaymentCollectedDate) : null,
    dateCompleted: apiDto.dateCompleted ? new Date(apiDto.dateCompleted) : null,
    stripeInvoicePaidDate: apiDto.stripeInvoicePaidDate ? new Date(apiDto.stripeInvoicePaidDate) : null,
});

export const mapToApiUpdateProposalMilestone = (dto: UpdateProposalMilestoneDto): ApiUpdateProposalMilestoneDto => {
    return {
        ...dto,
        clientPaymentCollectedDate: dto.clientPaymentCollectedDate ? dto.clientPaymentCollectedDate.toISOString() : undefined,
        dateCompleted: dto.dateCompleted ? dto.dateCompleted.toISOString() : undefined,
        stripeInvoicePaidDate: dto.stripeInvoicePaidDate ? dto.stripeInvoicePaidDate.toISOString() : undefined
    };
};