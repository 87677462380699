import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TalentBankDetailsDto } from "../api/models/talentBankDetails";
import FormCard from "./FormCard";

export type TalentBankDetailsViewProps = {
    bankDetails: TalentBankDetailsDto
}
const TalentBankDetailsView = ({
    bankDetails
}: TalentBankDetailsViewProps) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleCloseButton = () => {
        navigate(-1);
    };

    return (
        <FormCard
            title="Bank details"
            onClose={handleCloseButton}
        >
            <Box sx={{
                "> *:not(:last-child)": {
                    marginBottom: theme.spacing(3),
                }
            }}>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Bank name: <Typography sx={{ marginLeft: "8px" }} component="span">{bankDetails.bankName}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Bank account name: <Typography sx={{ marginLeft: "8px" }} component="span">{bankDetails.bankAccountName}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Bank account number: <Typography sx={{ marginLeft: "8px" }} component="span">{bankDetails.bankAccountNumber}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Routing number: <Typography sx={{ marginLeft: "8px" }} component="span">{bankDetails.bankRoutingNumber}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    IBAN: <Typography sx={{ marginLeft: "8px" }} component="span">{bankDetails.bankIban}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    BIC or SWIFT: <Typography sx={{ marginLeft: "8px" }} component="span">{bankDetails.bankBicOrSwift}</Typography>
                </Typography>
            </Box>
        </FormCard>
    );
};

export default TalentBankDetailsView;