import React from "react";
import { ApiGigMatch } from "../../api/models/api/gig";
import { List, ListItem, Typography } from "@mui/material";
import { useOrganizationConfig } from "../../api/organization";

export type GigMatchesDisplayProps = {
    matches: ApiGigMatch[];
}

const GigMatchesDisplay: React.FC<GigMatchesDisplayProps> = ( { matches }) => {
    
    const { gigDashboardDomain, talentTerminology, talentTerminologyPlural } = useOrganizationConfig();

    const talentStringPlural = talentTerminology === "Talent" ? "talent" : talentTerminologyPlural.toLowerCase();

    //sort matches by score in descending order and limit to top 5 if more than 5
    const sortedMatches = matches
        .slice()
        .sort((a, b) => b.score - a.score)
        .slice(0, Math.min(matches.length, 5));
    
    return (
        <>
            <Typography fontWeight="bold" component="label">
                Matches:
            </Typography>
            <List dense={true}>
                {
                    sortedMatches.map(match => (
                        <ListItem  key={match.talentId}  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <span style={{ textDecoration: "underline" }}>
                                <a href={`https://${gigDashboardDomain}/find-${talentStringPlural}/${match.talentId}`} target="_blank" >
                                    {match.talentFirstName + " " + match.talentLastName}
                                </a>
                            </span>
                            <span>
                                {Math.round(match.score*100) + "% match"}
                            </span>
                        </ListItem>
                    ))
                }
            </List>
        </>
    );
};

export default GigMatchesDisplay;