import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Loader from "../Loader";
import Table from "../Table";
import TableCard from "../TableCard";
import formatDateOnlyString from "../../utils/formatDateString";
import { useTalentWithdrawals } from "../../api/talentWithdrawals";
import TalentWithdrawalStatusCell from "./TalentWithdrawalStatusCell";
import MoneyDisplay from "../MoneyDisplay";
import { Link } from "react-router-dom";
import { useAppPaths } from "../../Routes";

export type TalentWithdrawalsTableProps = {
    searchTerm?: string
    withdrawalStatusIds: number[]
}

const TalentWithdrawalsTable = ({
    searchTerm,
    withdrawalStatusIds,
}: TalentWithdrawalsTableProps) => {
    const appPaths = useAppPaths();
    const talentWithdrawals = useTalentWithdrawals();

    const handleOrderByChange = (property: string) => {
        const isAsc = talentWithdrawals.orderBy === property && talentWithdrawals.sortOrder === "asc";
        talentWithdrawals.setSortOrder(isAsc ? "desc" : "asc");
        talentWithdrawals.setOrderBy(property);
    };

    const setSearchTerm = talentWithdrawals.setSearchTerm;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    useEffect(() => {
        talentWithdrawals.setWithdrawalStatusIds(withdrawalStatusIds);
    }, [talentWithdrawals.setWithdrawalStatusIds, withdrawalStatusIds]);

    if (talentWithdrawals.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "Id",
                        isSortable: false,
                    },
                    {
                        key: "Amount",
                        label: "Amount",
                        isSortable: true,
                    },
                    {
                        label: "Status",
                        isSortable: false,
                    },
                    {
                        key: "CreatedAt",
                        label: "Date created",
                        isSortable: true,
                    },
                    {
                        key: "TalentFirstName",
                        label: "First name",
                        isSortable: true,
                    },
                    {
                        key: "TalentLastName",
                        label: "Last name",
                        isSortable: true,
                    },
                    {
                        key: "TalentTradingName",
                        label: "Trading name",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false,
                    },
                ]}
                data={talentWithdrawals.withdrawals}
                renderRow={withdrawal => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{withdrawal.id}</TableCell>
                        <TableCell>{<MoneyDisplay value={withdrawal.amount} />}</TableCell>
                        <TalentWithdrawalStatusCell talentWithdrawalStatusId={withdrawal.statusId} />
                        <TableCell>{formatDateOnlyString(withdrawal.createdAt)}</TableCell>
                        <TableCell>{withdrawal.talentFirstName}</TableCell>
                        <TableCell>{withdrawal.talentLastName}</TableCell>
                        <TableCell>{withdrawal.talentTradingName || "-"}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.withdrawals.view(withdrawal.id)}>
                                <IconButton aria-label="view" >
                                    <VisibilityIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={talentWithdrawals.setPageIndex}
                setPageSize={talentWithdrawals.setPageSize}
                orderBy={talentWithdrawals.orderBy}
                sortOrder={talentWithdrawals.sortOrder}
                pageSize={talentWithdrawals.pageSize}
                pageIndex={talentWithdrawals.pageIndex}
                totalCount={talentWithdrawals.totalCount}
                isLoading={talentWithdrawals.isLoading}
            />
        </TableCard>
    );
};

export default TalentWithdrawalsTable;