import { NumericFormat } from "react-number-format";

export type MoneyDisplayProps = {
    value: number
}

const MoneyDisplay = ({ 
    value,
}: MoneyDisplayProps) => {
    return (
        <NumericFormat 
            value={value}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            displayType="text"
            renderText={(value) => `£${value}`}
        />
    );
};

export default MoneyDisplay;