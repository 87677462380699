import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { AddClientBadgeDto, ClientDetails, InviteClientUserDto, UpdateClientDto, ResendInviteClientUserDto } from "./models/client";

export const useUpdateClient = () => useAuthenticatedGigApiFetcher<UpdateClientDto, void, { clientId: string, dto: UpdateClientDto }>("PUT",
    ({ clientId, dto }) => ({
        path: `api/gigged-clients/${clientId}`,
        body: dto
    }));

export const useDeleteUserFromClient = () => useAuthenticatedGigApiFetcher<string, void, { clientId: string, userId: string }>("DELETE",
    ({ clientId, userId }) => ({
        path: `api/gigged-clients/${clientId}/users/${userId}`,
    }));

export const useInviteClientUser = () => useAuthenticatedGigApiFetcher<InviteClientUserDto, void, { clientId: string, dto: InviteClientUserDto }>("POST",
    ({ clientId, dto }) => ({
        path: `api/gigged-clients/${clientId}/actions/invite-user`,
        body: dto
    }));

export const useResendInviteClientUser = () => useAuthenticatedGigApiFetcher<ResendInviteClientUserDto, void, { clientId: string, dto: ResendInviteClientUserDto }>("PUT",
    ({ clientId, dto }) => ({
        path: `api/gigged-clients/${clientId}/actions/invite-user/resend`,
        body: dto
    }));

export const useDeleteClient = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (clientId) => ({
        path: `api/gigged-clients/${clientId}`,
    }));

export const useAddClientBadge = () => useAuthenticatedGigApiFetcher<AddClientBadgeDto, void, { clientId: string, dto: AddClientBadgeDto }>("POST",
    ({ clientId, dto }) => ({
        path: `api/gigged-clients/${clientId}/badges/${dto.badgeId}`
    }));

export type UseClientReturn<TClientId extends string | undefined> = TClientId extends undefined ?
    { client: undefined } :
    {
        client?: ClientDetails
        isLoading: boolean
        error?: ApiError
        deleteUserFromClient: (userId: string) => Promise<GigApiFetcherResponse<void>>
        updateClient: (dto: UpdateClientDto) => Promise<GigApiFetcherResponse<void>>
        inviteClientUser: (dto: InviteClientUserDto) => Promise<GigApiFetcherResponse<void>>
        resendInviteClientUser: (dto: ResendInviteClientUserDto) => Promise<GigApiFetcherResponse<void>>
        deleteClient: (clientId: string) => Promise<GigApiFetcherResponse<void>>
        addClientBadge: (dto: AddClientBadgeDto) => Promise<GigApiFetcherResponse<void>>
        reload: () => void
        isAddingClientBadge: boolean
        isUpdatingClient: boolean
        isDeletingUserFromClient: boolean
        isInvitingClientUser: boolean
        isDeletingClient: boolean
    }

export const useClient = <TClientId extends string | undefined>(clientId: TClientId): UseClientReturn<TClientId> => {

    const url = `api/gigged-clients/${clientId}`;

    const apiCall = useGiggedApiSWR<ClientDetails>(url);

    const [updateClient, isUpdatingClient] = useUpdateClient();
    const [deleteUserFromClient, isDeletingUserFromClient] = useDeleteUserFromClient();
    const [inviteClientUser, isInvitingClientUser] = useInviteClientUser();
    const [resendInviteClientUser] = useResendInviteClientUser();
    const [deleteClient, isDeletingClient] = useDeleteClient();
    const [addClientBadge, isAddingClientBadge] = useAddClientBadge();

    if (clientId === undefined) return { client: undefined } as UseClientReturn<TClientId>;

    return {
        client: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        deleteUserFromClient: async (userId: string) => {
            const response = await deleteUserFromClient({ clientId, userId });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        updateClient: async (dto: UpdateClientDto) => {
            const response = await updateClient({ clientId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        inviteClientUser: async (dto: InviteClientUserDto) => {
            const response = await inviteClientUser({ clientId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        resendInviteClientUser: async (dto: ResendInviteClientUserDto) => {
            const response = await resendInviteClientUser({ clientId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        deleteClient: async (clientId: string) => {
            const response = await deleteClient(clientId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        addClientBadge: async (dto: AddClientBadgeDto) => {
            const response = await addClientBadge({ clientId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        reload: apiCall.mutate,
        isAddingClientBadge,
        isUpdatingClient,
        isDeletingUserFromClient,
        isInvitingClientUser,
        isDeletingClient,
    } as UseClientReturn<TClientId>;
};
