import { Typography, Box } from "@mui/material";
import TalentWithdrawalsTable from "../components/TalentWithdrawalsTable";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

const TalentWithdrawalsListPage = () => {
    const isLargeScreen = useIsLargeScreen();
    
    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 ) }}>
                <Typography variant="h4" gutterBottom>Withdrawals</Typography>
                <TalentWithdrawalsTable />
            </Box>
        </>
    );
};

export default TalentWithdrawalsListPage;