import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { TalentWithdrawalSummaryDto } from "./models/talentWithdrawal";

export type UseTalentWithdrawalsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<TalentWithdrawalSummaryDto>>, "data"> & {
        withdrawals: TalentWithdrawalSummaryDto[]
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        orderBy?: string
        sortOrder?: SortOrder
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setWithdrawalStatusIds: (statusIds: number[]) => void
    };

export const useTalentWithdrawals = (initialPageSize = 10): UseTalentWithdrawalsReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("CreatedAt");
    const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [withdrawalStatusIds, setWithdrawalStatusIds] = useState<number[]>([]);

    const url = "api/withdrawals?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        (withdrawalStatusIds.length > 0 ? `&withdrawalStatusIds=${withdrawalStatusIds.join(",")}` : "");

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<TalentWithdrawalSummaryDto>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        withdrawals: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        setPageIndex,
        setPageSize,
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setWithdrawalStatusIds,
    };
};