import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";
import { useOrganizationConfig } from "../../api/organization";

export type ClientEditFormValues = {
    name: string
    isTurnoverChecked: boolean    
    isBalanceSheetChecked: boolean
    isEmployeesChecked: boolean
    aboutMe: string
    companyName?: string
    companyTagline?: string
    companyWebsite?: string
    companyNumber?: string
    enablePurchaseOrders: boolean
    phoneNumber?: string
    badgeId: string
    locationId?: string
    isWorksome: boolean
};

export const useEditClientForm = (initialValues?: ClientEditFormValues) => {
    const schema = clientValidationSchema();
    
    return useForm<ClientEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            name: initialValues?.name ?? "",
            aboutMe: initialValues?.aboutMe ?? "",
            companyName: initialValues?.companyName ?? "",
            companyTagline: initialValues?.companyTagline ?? "",
            companyNumber: initialValues?.companyNumber ?? "",
            companyWebsite: initialValues?.companyWebsite ?? "",
            enablePurchaseOrders: initialValues?.enablePurchaseOrders ?? false,
            phoneNumber: initialValues?.phoneNumber ?? "",
            badgeId: initialValues?.badgeId ?? "",
            locationId: initialValues?.locationId ?? "",
            isWorksome: initialValues?.isWorksome ?? false
        },
    });
};

const clientValidationSchema = () => {
    const { giggedClientTerminology } = useOrganizationConfig();
    return yup
        .object({
            name: yup.string().required(`${giggedClientTerminology} name is required.`),
            isEmployeesChecked: yup.boolean(),
            isTurnoverChecked: yup.boolean(),
            isBalanceSheetChecked: yup.boolean(),
            aboutMe: yup.string().required("Overview is required."),
            companyName: yup.string().max(100, "Company name cannot be longer than 100 characters.").required("Company name is required."),
            companyTagline: yup.string().max(60, "Tagline cannot be longer than 60 characters."),
            companyWebsite: yup.string().test(isValidOptionalUrl),
            companyNumber: yup.string().max(8, "Company number cannot be longer than 8 characters."),
            enablePurchaseOrders: yup.boolean(),
            badgeId: yup.string()
        })
        .required();
};