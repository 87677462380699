import { Typography } from "@mui/material";
import { useOrganizationConfig } from "../../api/organization";
import MoneyDisplay from "../MoneyDisplay";

export type BudgetDisplayProps = {
    budget: number
}

const BudgetDisplay = ({
    budget
}: BudgetDisplayProps) => {
    const { gigTerminology } = useOrganizationConfig();

    return (
        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
            {gigTerminology} budget: <Typography component="span"><MoneyDisplay value={budget} /></Typography>
        </Typography>
    );
};

export default BudgetDisplay;