import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, MenuItem, Select, useTheme } from "@mui/material";
import { Controller, FormProvider, UseFormReturn } from "react-hook-form";

import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import FormTextInput from "../FormTextInput";
import { SettingsUpdateFormValues } from "./SettingsUpdateFormValues";
import ToggleButton from "../ToggleButton";
import FormChipInput from "../FormChipInput";
import { AddLogoToOrganizationDto, OrganizationConfigDto, organizationThemeOptions } from "../../api/models/organization";
import { organizationSettingsTooltips } from "../../tooltipsContent";
import FormErrors from "../FormErrors";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useOrganizationConfig } from "../../api/organization";
import FormFileUploadInput, { useFormFileUploadInputState } from "../FormFileUploadInput";
import FormInputLabel from "../FormInputLabel";

export type SettingsUpdateFormProps = {
    onSubmit: (values: SettingsUpdateFormValues) => Promise<GigApiFetcherResponse<void>>
    onLogoSubmit: (dto: AddLogoToOrganizationDto) => Promise<GigApiFetcherResponse<void>>
    isSubmitting: boolean
    isSubmittingLogo: boolean
    initialValues: OrganizationConfigDto
    methods: UseFormReturn<SettingsUpdateFormValues>
}

const SettingsUpdateForm = ({
    onSubmit,
    onLogoSubmit,
    isSubmitting,
    isSubmittingLogo,
    initialValues,
    methods
}: SettingsUpdateFormProps) => {
    const { talentTerminology, giggedClientTerminology } = useOrganizationConfig();
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const isLargeScreen = useIsLargeScreen();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const {
        formState: { isDirty },
        handleSubmit,
        control,
        reset,
        watch } = methods;

    const {
        urlOrDataUri: organizationLogoDataUri,
        loadFile,
        error: organizationLogoError,
        isDirty: isOrganizationLogoDirty,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: initialValues.logoUrl,
    });

    useEffect(() => {
        if (settingsLoaded) return;

        reset({
            ...initialValues,
            termsAndConditionsUrl: initialValues.termsAndConditionsUrl,
            isEmailDomainWhitelisted: initialValues.isEmailDomainWhitelisted,
            whitelistedEmailDomains: initialValues.whitelistedEmailDomains
        });

        if (initialValues) {
            setSettingsLoaded(true);
        }
    }, [reset, initialValues, settingsLoaded]);

    useEffect(() => {
        if (organizationLogoDataUri && organizationLogoDataUri !== initialValues.logoUrl) {
            onLogoSubmit({ logoDataUri: organizationLogoDataUri });
        }
    }, [organizationLogoDataUri]);

    const handleSaveChanges = async (values: SettingsUpdateFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDiscardChanges = () => {
        if (isDirty) {
            openCancelDialog();
        }
    };

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            reset(initialValues);
        }
    });

    const emailWhitelistingEnabled = watch().isEmailDomainWhitelisted;

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleSaveChanges)}
                    noValidate
                >
                    <FormCard
                        title="Organisation Settings"
                    >
                        <Box className="space-y-5">
                            <FormFileUploadInput
                                {...fileInputProps}
                                label="Organisation logo"
                                helpText="Recommended minimum size: 130x25px"
                                inputId="organisation-logo-image-upload-button"
                                onLoadFile={loadFile}
                                error={organizationLogoError}
                                dataUrl={organizationLogoDataUri}
                                isDirty={isOrganizationLogoDirty}
                                disabled={isSubmittingLogo}
                            />
                            <FormTextInput
                                name="termsAndConditionsUrl"
                                label="Terms &amp; Conditions URL"
                                disabled={isSubmitting}
                                required
                            />
                            <FormTextInput
                                name="tagline"
                                label="Tagline"
                                disabled={isSubmitting}
                            />
                            <Box className="space-y-3">
                                <Controller
                                    name="isTalentProfilesPrivate"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleButton
                                            label={`Private ${talentTerminology.toLowerCase()} profiles`}
                                            onChange={onChange}
                                            checked={value}
                                            tooltip={organizationSettingsTooltips.isTalentProfilesPrivate(talentTerminology, giggedClientTerminology)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="isDisputesEnabled"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleButton
                                            label={"Disputes feature enabled"}
                                            onChange={onChange}
                                            checked={value}
                                            tooltip={organizationSettingsTooltips.isDisputesEnabled}
                                        />
                                    )}
                                />
                                <Controller
                                    name="isProposalReviewsEnabled"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleButton
                                            label={"Proposal reviews enabled"}
                                            onChange={onChange}
                                            checked={value}
                                            tooltip={organizationSettingsTooltips.proposalReviews}
                                        />
                                    )}
                                />
                                <Controller
                                    name="isEmailDomainWhitelisted"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleButton
                                            label={"Email domain whitelisting enabled"}
                                            onChange={onChange}
                                            checked={value}
                                            tooltip={organizationSettingsTooltips.isEmailDomainWhitelisted}
                                        />
                                    )}
                                />
                                {emailWhitelistingEnabled &&
                                    <FormChipInput
                                        placeholder="Type an email domain and press enter"
                                        name="whitelistedEmailDomains"
                                        title="Add whitelisted email domains"
                                        tooltip={organizationSettingsTooltips.addWhitelistedEmails}
                                        required
                                    />
                                }
                            </Box>
                            <Box className="space-y-3">
                                <FormInputLabel tooltipText={organizationSettingsTooltips.overrideTerminologies}>
                                    Override terminologies
                                </FormInputLabel>
                                <Box className="space-y-5">
                                    <FormTextInput
                                        name="gigTerminology"
                                        label="Gig terminology"
                                        disabled={isSubmitting}
                                    />
                                    <FormTextInput
                                        name="giggedClientTerminology"
                                        label="Client terminology"
                                        disabled={isSubmitting}
                                    />
                                    <FormTextInput
                                        name="talentTerminology"
                                        label="Talent terminology"
                                        disabled={isSubmitting}
                                    />
                                </Box>
                            </Box>
                            <Box className="space-y-2 flex flex-col">
                                <FormInputLabel tooltipText={organizationSettingsTooltips.theme}>Choose theme</FormInputLabel>
                                <Controller
                                    name="theme"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            variant="outlined"
                                            color="secondary"
                                        >
                                            {organizationThemeOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Box>

                        <FormErrors messages={submissionError?.userMessages} />

                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "space-between", marginTop: "2rem" } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "1.5rem" }
                        }>
                            <Button
                                className="block mx-auto"
                                type="button"
                                onClick={handleDiscardChanges}
                                disabled={!isDirty}
                            >
                                Discard changes
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
                cancelButtonText="Back"

            />
        </>
    );
};

export default SettingsUpdateForm;