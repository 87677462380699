import { useMediaQuery, Box, Typography, useTheme } from "@mui/material";
import ProposalsTable from "../components/ProposalsTable";

const ProposalsListPage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
    
    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 ) }}>
                <Typography variant="h4" gutterBottom>Proposals</Typography>
                <ProposalsTable />
            </Box>
        </>
    );
};

export default ProposalsListPage;
