import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import SearchBox from "../SearchBox";
import ProposalsTable from "./ProposalsTable";
import { useNavigate, useParams } from "react-router-dom";
import DeletableChips from "../DeletableChips";
import { useAppPaths } from "../../Routes";
import { useProposals } from "../../api/proposals";
import { useOrganizationConfig } from "../../api/organization";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";

const ProposalsTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const navigate = useNavigate();
    const isLargeScreen = useIsLargeScreen();
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const [searchTerm, setSearchTerm] = useState("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { gigId } = useParams<{ gigId: string }>();
    const proposals = useProposals();
    const { gigTerminology, talentTerminology } = useOrganizationConfig();

    const filteredGigTitle = proposals.proposals.filter(proposal => proposal.gigId === gigId)[0]?.gigTitle;

    const filterChips = gigId && (
        <DeletableChips
            chips={[
                {
                    label: `Filtered by: ${filteredGigTitle}`,
                    onDelete: () => {
                        navigate(appPaths.gigs.index);
                    },
                    visible: gigId ? true : false
                }
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
                maxWidth: "18rem",
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? `Search by ${gigTerminology} or ${talentTerminology} name` : "Search Proposals"}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ?  { minWidth: "28rem", background: "#fff" } : { flexGrow: 1, background: "#fff" }}
                />
                {showFilterChipsInline && filteredGigTitle && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
            </Box>
            {!showFilterChipsInline && filteredGigTitle && (
                <Box sx={{
                    marginTop: "1.5rem"
                }}>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <ProposalsTable 
                    proposals={proposals}
                    searchTerm={debounceSearchTerm}
                    gigId={gigId}
                />
            </Box>
        </>
    );
};

export default ProposalsTableWithSearch;