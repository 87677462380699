import { useMediaQuery, Box, Typography, useTheme } from "@mui/material";
import MilestonesTable from "../components/ProposalMilestonesTable";

const MilestonesListPage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box sx={{ marginTop: "2rem", margin: (isLargeScreen ? 3 : 2) }}>
            <Typography variant="h4" gutterBottom>Milestones</Typography>
            <MilestonesTable />
        </Box>
    );
};

export default MilestonesListPage;
