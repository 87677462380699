import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { TalentWithdrawalDetailsDto, UpdateTalentWithdrawalDto } from "./models/talentWithdrawal";

export const useUpdateWithdrawal = () => useAuthenticatedGigApiFetcher<UpdateTalentWithdrawalDto, void, { withdrawalId: string, statusId: number }>("PUT",
    ({ withdrawalId, statusId}) => ({
        path: `api/withdrawals/${withdrawalId}`,
        body: {
            statusId
        }
    }));
    
export type UseWithdrawalReturn<TWithdrawalId extends string | undefined> = TWithdrawalId extends undefined ?
    { withdrawal: undefined } :
    {
        withdrawal: TalentWithdrawalDetailsDto
        updateWithdrawalStatus: (statusId: number) => Promise<GigApiFetcherResponse<void>>
        isUpdatingWithdrawalStatus: boolean
    }

export const useTalentWithdrawal = <TWithdrawalId extends string | undefined>(withdrawalId: TWithdrawalId) => {
    const url = `api/withdrawals/${withdrawalId}`;
    const apiCall = useGiggedApiSWR<TalentWithdrawalDetailsDto>(url);
    
    const [updateWithdrawal, isUpdatingWithdrawalStatus] = useUpdateWithdrawal();

    if (withdrawalId === undefined) return { withdrawal: undefined } as UseWithdrawalReturn<TWithdrawalId>;

    return {
        ...apiCall,
        withdrawal: apiCall.data,
        updateWithdrawalStatus: async (statusId: number) => {
            const response = await updateWithdrawal({
                withdrawalId,
                statusId,
            });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingWithdrawalStatus,
    } as UseWithdrawalReturn<TWithdrawalId>;
};