import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { TalentPayoneerEmailDto } from "./models/talent";

export type UseTalentPayoneerEmailReturn<TTalentId extends string | undefined> = TTalentId extends undefined ?
    { talentPayoneerEmail: undefined } :
    {
        talentPayoneerEmail: TalentPayoneerEmailDto
        isLoading: boolean
    }

export const useTalentPayoneerEmail = <TTalentId extends string | undefined>(talentId: TTalentId): UseTalentPayoneerEmailReturn<TTalentId> => {
    const url = `api/talent/${talentId}/payoneer-account`;

    const apiCall = useGiggedApiSWR<TalentPayoneerEmailDto>(url);


    if (talentId === undefined) return { talentPayoneerEmail: undefined } as UseTalentPayoneerEmailReturn<TTalentId>;

    return {
        ...apiCall,
        talentPayoneerEmail: apiCall.data, 
        isLoading: apiCall.isLoading,
    } as UseTalentPayoneerEmailReturn<TTalentId>;
};