import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type SkillCreateFormValues = {
    skillName: string
    skillCategoryId: string
};

export const useSkillsForm = () => {
    const schema = skillCreateValidationSchema();

    return useForm<SkillCreateFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
};

const skillCreateValidationSchema = () => {
    return yup
        .object({
            skillName: yup.string().required("Skill name is required."),
            skillCategoryId: yup.string().required("Skill category is required")
        })
        .required();
};