import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useClients } from "../../api/clients";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import { useAppPaths } from "../../Routes";
import TableChipCell from "../Table/TableChipCell";
import formatDateString from "../../utils/formatDateString";
import { giggedClientSignUpReasonOptions } from "../../api/models/client";

export type ClientsTableProps = {
    searchTerm?: string
    ir35Filter?: boolean
    purchaseOrderFilter?: boolean
    isTurnoverCheckedFilter?: boolean
    badgeIds: string[],
}

const ClientsTable = ({
    searchTerm,
    ir35Filter,
    purchaseOrderFilter,
    isTurnoverCheckedFilter,
    badgeIds
}: ClientsTableProps) => {
    const appPaths = useAppPaths();
    const clients = useClients();
    const { giggedClientTerminology, giggedClientTerminologyPlural, organizationConfig, gigDashboardDomain } = useOrganizationConfig();
    const { setSearchTerm, setIr35Filter, setPurchaseOrderFilter, setIsTurnoverChecked, setBadgeIds } = clients;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    useEffect(() => {
        setIr35Filter(ir35Filter);
    }, [setIr35Filter, ir35Filter]);

    useEffect(() => {
        setPurchaseOrderFilter(purchaseOrderFilter);
    }, [setPurchaseOrderFilter, purchaseOrderFilter]);

    useEffect(() => {
        setIsTurnoverChecked(isTurnoverCheckedFilter);
    }, [setIsTurnoverChecked, isTurnoverCheckedFilter]);

    useEffect(() => {
        setBadgeIds(badgeIds);
    }, [setBadgeIds, badgeIds]);

    const handleOrderByChange = (property: string) => {
        const isAsc = clients.orderBy === property && clients.sortOrder === "asc";
        clients.setSortOrder(isAsc ? "desc" : "asc");
        clients.setOrderBy(property);
    };

    const headers = [
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "Name",
            label: organizationConfig?.hideGiggedClientCompanyInfo ? `${giggedClientTerminology} name` : "Display name",
            isSortable: true,
        },
    ];

    if (organizationConfig?.isPaymentsEnabled || !organizationConfig?.hideGiggedClientCompanyInfo) {
        headers.push(
            {
                key: "CompanyName",
                label: "Company name",
                isSortable: true,
            },
        );
    }

    headers.push(
        {
            key: "UserEmail",
            label: "User email",
            isSortable: false,
        },
        {
            key: "UserName",
            label: "User full name",
            isSortable: false,
        },
    );

    if (organizationConfig?.isPaymentsEnabled || !organizationConfig?.hideGiggedClientCompanyInfo) {
        headers.push(
            {
                key: "PhoneNumber",
                label: "Tel",
                isSortable: false
            },
        );
    }

    if (organizationConfig?.isPaymentsEnabled) {
        headers.push(
            {
                label: "IR35 check required",
                isSortable: false
            },
            {
                label: "Purchase orders enabled",
                isSortable: false
            },
            {
                label: "Business type",
                isSortable: false
            }
        );
    }

    if (organizationConfig?.isBadgesEnabled) {
        headers.push(
            {
                label: organizationConfig?.name === "Gigged.AI" ? "Verification status" : "Badge",
                isSortable: false
            }
        );
    }

    headers.push(
        {
            label: "Sign up reason",
            isSortable: false
        },
        {
            key: "CreatedAt",
            label: "Date created",
            isSortable: true
        },
        {
            label: "View profile",
            isSortable: false
        },
        {
            label: "",
            isSortable: false
        });

    if (clients.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={clients.clients}
                renderRow={client => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{client.id}</TableCell>
                        <TableCell sx={{ 
                            whiteSpace: "nowrap",
                            backgroundImage: client.isWorksome ? "url('/worksome-icon-40x40.svg')" : "none",
                            backgroundRepeat: "no-repeat", 
                            backgroundPosition: "right 2px center",
                            backgroundSize: "30px 30px",
                        }}>
                            {client.name || "-"}
                        </TableCell>
                        {(organizationConfig?.isPaymentsEnabled || !organizationConfig?.hideGiggedClientCompanyInfo) && <TableCell>{client.companyName || "-"}</TableCell>}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.userEmail || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.userFirstName || "-"} {client.userLastName}</TableCell>
                        {(organizationConfig?.isPaymentsEnabled || !organizationConfig?.hideGiggedClientCompanyInfo) && <TableCell sx={{ whiteSpace: "nowrap" }}>{client.phoneNumber || "-"}</TableCell>}
                        {organizationConfig?.isPaymentsEnabled && (
                            <>
                                <TableChipCell labels={[client.isIR35CheckRequired ? "Yes" : ""]} />
                                <TableChipCell labels={[client.enablePurchaseOrders ? "Yes" : ""]} />
                                <TableChipCell labels={[client.isTurnoverChecked ? "Enterprise" : "SMB"]} />
                            </>
                        )}
                        {organizationConfig?.isBadgesEnabled && <TableChipCell labels={[!client.badge ? "" : client.badge.badgeName]} />}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.signUpReasonId ? giggedClientSignUpReasonOptions[client.signUpReasonId].label : "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateString(client.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                            <a href={`https://${gigDashboardDomain}/${giggedClientTerminologyPlural.toLowerCase()}/${client.id}`} target="_blank">
                                <VisibilityIcon color="secondary" />
                            </a>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.clients.edit(client.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={clients.setPageIndex}
                setPageSize={clients.setPageSize}
                pageSize={clients.pageSize}
                pageIndex={clients.pageIndex}
                totalCount={clients.totalCount}
                orderBy={clients.orderBy}
                sortOrder={clients.sortOrder}
                isLoading={clients.isLoading}
            />
        </TableCard>
    );
};

export default ClientsTable;