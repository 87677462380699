import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton, TableCell } from "@mui/material";
import { Link } from "react-router-dom";

import { useTalents } from "../../api/talents";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import TableChipCell from "../Table/TableChipCell";
import formatDateOnlyString from "../../utils/formatDateString";
import { useAppPaths } from "../../Routes";
import { useOrganizationConfig } from "../../api/organization";
import MoneyDisplay from "../MoneyDisplay";
import clsx from "clsx";
import { TableHeaderConfig } from "../Table/Table";

export type TalentsTableProps = {
    searchTerm?: string,
    skillIds: string[] | undefined
    badgeIds: string[] | undefined
}

const TalentsTable = ({ 
    searchTerm, 
    skillIds, 
    badgeIds 
}: TalentsTableProps) => {
    const talents = useTalents();
    const { gigDashboardDomain, organizationConfig, gigTerminologyPlural, talentTerminology, talentTerminologyPlural } = useOrganizationConfig();
    const setSearchTerm = talents.setSearchTerm;
    const appPaths = useAppPaths();

    const talentStringPlural = talentTerminology === "Talent" ? "talent" : talentTerminologyPlural.toLowerCase();

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    const handleOrderByChange = (property: string) => {
        const isAsc = talents.orderBy === property && talents.sortOrder === "asc";
        talents.setSortOrder(isAsc ? "desc" : "asc");
        talents.setOrderBy(property);
    };

    useEffect(() => {
        talents.setSkillIds(skillIds ?? []);
    }, [talents.setSkillIds, skillIds]);

    useEffect(() => {
        talents.setBadgeIds(badgeIds ?? []);
    }, [talents.setBadgeIds, badgeIds]);

    if (talents.isLoading) return (
        <Loader />
    );

    const headers: TableHeaderConfig[] = [
        {
            label: "",
            isSortable: false,
        },
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "FirstName",
            label: "First name",
            isSortable: true,
        },
        {
            key: "LastName",
            label: "Last name",
            isSortable: true,
        },
        {
            key: "TradingName",
            label: "Trading name",
            isSortable: true,
        },
    ];

    if (organizationConfig?.isTalentAgencyEnabled) {
        headers.push(
            {
                label: "Agency name",
                isSortable: false,
            },
        );
    }
    if (organizationConfig?.isTalentAvailabilityEnabled) {
        headers.push(
            { 
                label: "Availability", 
                isSortable: false 
            },
        );
    }

    headers.push(
        {
            key: "Email",
            label: "Email",
            isSortable: true,
        },
        {
            label: `Ongoing ${gigTerminologyPlural.toLowerCase()}`,
            isSortable: false,
        },
        {
            label: `Completed ${gigTerminologyPlural.toLowerCase()}`,
            isSortable: false,
        },
        {
            label: `Cancelled ${gigTerminologyPlural.toLowerCase()}`,
            isSortable: false,
        },
    );

    if (organizationConfig?.isPaymentsEnabled) {
        headers.push({
            key: "TotalEarnings",
            label: "Total earnings",
            isSortable: true,
        });
    }

    headers.push(
        {
            key: "ProfileHealthScore",
            label: "Profile health score",
            isSortable: true,
        },
        {
            key: "CreatedAt",
            label: "Date registered",
            isSortable: true,
        }
    );

    if (organizationConfig?.isPaymentsEnabled) {
        headers.push(
            {
                label: "Bank details",
                isSortable: false,
            },
            {
                label: "Payoneer details",
                isSortable: false,
            });
    }

    headers.push(
        {
            label: "View profile",
            isSortable: false,
        });

    if (organizationConfig?.isTalentAvailabilityEnabled || organizationConfig?.isTalentAgencyEnabled) {
        headers.push(
            {
                label: "",
                isSortable: false
            });
    }

    return (
        <TableCard>
            <Table
                headers={headers}
                data={talents.talents}
                rowHeight="73.5px"
                renderRow={talent => (
                    <>
                        <TableCell>
                            {talent.profileImageThumbnailUrl && (
                                <img
                                    src={talent.profileImageThumbnailUrl}
                                    className="h-10 w-10 object-cover rounded-full shrink-0 min-w-[2.5rem]"
                                />
                            )}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{talent.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.firstName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.lastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.tradingName || "-"}</TableCell>
                        {organizationConfig?.isTalentAgencyEnabled && <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.agencyName || "-"}</TableCell>}
                        {organizationConfig?.isTalentAvailabilityEnabled && (
                            talent.organizationAvailability ?
                                <TableCell>{talent.organizationAvailability}</TableCell> :
                                <TableChipCell labels={["Available"]} />
                        )}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.email || "-"}</TableCell>
                        <TableCell className={clsx(talent.ongoingGigsCount === 0 && "!text-[#BBB]")}>{talent.ongoingGigsCount}</TableCell>
                        <TableCell className={clsx(talent.completedGigsCount === 0 && "!text-[#BBB]")}>{talent.completedGigsCount}</TableCell>
                        <TableCell className={clsx(talent.cancelledGigsCount === 0 && "!text-[#BBB]")}>{talent.cancelledGigsCount}</TableCell>
                        {organizationConfig?.isPaymentsEnabled && (
                            <TableCell className={clsx(talent.totalEarnings === 0 && "!text-[#BBB]")}><MoneyDisplay value={talent.totalEarnings} /></TableCell>
                        )}
                        <TableCell>{talent.profileHealthScore}%</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(talent.createdAt)}</TableCell>
                        {organizationConfig?.isPaymentsEnabled && (
                            <>
                                <TableCell sx={{ textAlign: "left" }}>
                                    {talent.hasEnteredBankDetails ? (
                                        <Link to={appPaths.talents.bankDetails(talent.id)}>
                                            <VisibilityIcon color="secondary" />
                                        </Link>
                                    ) : (
                                        <span>-</span>
                                    )}
                                </TableCell>
                                <TableCell sx={{ textAlign: "left" }}>
                                    {talent.hasEnteredPayoneerDetails ? (
                                        <Link to={appPaths.talents.payoneerDetails(talent.id)}>
                                            <VisibilityIcon color="secondary" />
                                        </Link>
                                    ) : (
                                        <span>-</span>
                                    )}
                                </TableCell>
                            </>
                        )}
                        <TableCell sx={{ textAlign: "left" }}>
                            <a href={`https://${gigDashboardDomain}/find-${talentStringPlural}/${talent.id}`} target="_blank">
                                <VisibilityIcon color="secondary" />
                            </a>
                        </TableCell>
                        {(organizationConfig?.isTalentAvailabilityEnabled || organizationConfig?.isTalentAgencyEnabled) && (
                            <>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <Link to={appPaths.talents.edit(talent.id)}>
                                        <IconButton aria-label="edit" >
                                            <ModeEditOutlineOutlinedIcon color="secondary" />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </>
                        )}
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={talents.setPageIndex}
                setPageSize={talents.setPageSize}
                pageSize={talents.pageSize}
                pageIndex={talents.pageIndex}
                totalCount={talents.totalCount}
                orderBy={talents.orderBy}
                sortOrder={talents.sortOrder}
                isLoading={talents.isLoading}
            />
        </TableCard>
    );
};

export default TalentsTable;
