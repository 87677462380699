import { Box, Typography } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";

export type ProposalCountDisplayProps = {
    proposalsCount: number
}

const ProposalCountDisplay = ({
    proposalsCount
}: ProposalCountDisplayProps) => {
    const proposalsText = proposalsCount && proposalsCount === 1 ? `${proposalsCount} Proposal` : `${proposalsCount} Proposals`;
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
            }}
        >
            <FilePresentIcon sx={{ marginRight: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography component="span">
                    {proposalsText}
                </Typography>
            </Box>
        </Box>
    );
};

export default ProposalCountDisplay;