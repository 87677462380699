import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TalentPayoneerEmailDto } from "../api/models/talent";
import FormCard from "./FormCard";

export type TalentPayoneerDetailsViewProps = {
    payoneerEmail: TalentPayoneerEmailDto
}
const TalentPayoneerDetailsView = ({
    payoneerEmail
}: TalentPayoneerDetailsViewProps) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleCloseButton = () => {
        navigate(-1);
    };

    return (
        <FormCard
            title="Payoneer details"
            onClose={handleCloseButton}
        >
            <Box sx={{
                "> *:not(:last-child)": {
                    marginBottom: theme.spacing(3),
                }
            }}>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Payoneer email: <Typography sx={{ marginLeft: "8px" }} component="span">{payoneerEmail.payoneerEmail}</Typography>
                </Typography>
            </Box>
        </FormCard>
    );
};

export default TalentPayoneerDetailsView;