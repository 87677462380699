import { useNavigate } from "react-router-dom";
import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useGigs } from "../api/gigs";
import { GigCreateForm, GigCreateFormValues } from "../components/GigCreate";

const GigCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createGig, isCreatingGig } = useGigs();

    const onSubmit = async (values: GigCreateFormValues): Promise<GigApiFetcherResponse<string>> => {

        if (values.startDate === null) throw Error("Expected start date not to be null.");

        const response = await createGig({
            ...values,
            title: values.title,
            description: values.description,
            expectedDurationId: values.expectedDurationId,
            initialStageId: values.initialStageId,
            deadlineDate: values.deadlineDate,
            budget: values.budget,
            isCompletedRemotely: values.isCompletedRemotely,
            startDate: values.startDate,
            skillIds: values.skills.map(_ => _.id),
            gigStatusId: values.gigStatusId,
            giggedClientId: values.giggedClientId,
        });

        if (response.success) {
            successToast("Gig successfully created.");
            navigate(appPaths.gigs.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <GigCreateForm onSubmit={onSubmit} isSubmitting={isCreatingGig} />
        </FormContainer>
    );
};

export default GigCreatePage;