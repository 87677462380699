import FormContainer from "../components/FormContainer";
import { useWithdrawalId } from "../Routes";
import { successToast } from "../toast";
import { useTalentWithdrawal } from "../api/talentWithdrawal";
import { TalentWithdrawalViewPage } from "../components/TalentWithdrawalView";
import Loader from "../components/Loader";

const TalentWithdrawalPage = () => {
    const withdrawalId = useWithdrawalId();
    const { withdrawal, updateWithdrawalStatus, isUpdatingWithdrawalStatus } = useTalentWithdrawal(withdrawalId);

    const handleUpdateStatus = async (statusId: number) => {
        const response = await updateWithdrawalStatus(statusId);

        if (response.success) successToast("Status updated.");

        return response;
    };

    if (!withdrawal) return <Loader />;

    return (
        <FormContainer>
            <TalentWithdrawalViewPage
                withdrawal={withdrawal}
                onUpdateStatus={handleUpdateStatus}
                isUpdatingStatus={isUpdatingWithdrawalStatus}
            />
        </FormContainer>
    );
};

export default TalentWithdrawalPage;