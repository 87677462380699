import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAccessToken from "../../auth/useAccessToken";
import { validateClientEmail } from "../../api/organizations";
import { isApiError } from "../../api/common/apiError";
import { useOrganizationConfig } from "../../api/organization";

export type ClientCreateFormValues = {
    name: string,
    userEmails: string[],
};

export const useClientForm = (initialValues?: Partial<ClientCreateFormValues>) => {
    const schema = clientValidationSchema();
    
    return useForm<ClientCreateFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            name: initialValues ? initialValues.name : "",
            userEmails: [],
        },
    });
};

const clientValidationSchema = () => {
    const token = useAccessToken();
    const { giggedClientTerminology, giggedClientTerminologyPlural } = useOrganizationConfig();

    return yup
        .object({
            name: yup.string().required(`${giggedClientTerminology} name is required.`),
            userEmails: yup.array().of(yup.string()
                .email(error => `"${error.value}" is an invalid email`)
                .test("validate-email", "\"${value}\" is already a member of another " + giggedClientTerminology.toLowerCase() + " account. You can manage users from the " + giggedClientTerminologyPlural.toLowerCase() + "table.", async (value, context) => {
                    if (value === undefined) return true;
                    if (token === null) throw new Error("Expected token in context when validating email.");
                    try {
                        if ((context.parent.filter((item: string) => item == value).length) > 1) {
                            return context.createError({ message: "Email address \"${value}\" has been entered more than once." });
                        } else {
                            await validateClientEmail(value, token);
    
                            return true;
                        }
                    }
                    catch (error) {
                        if (isApiError(error)) {
                            return context.createError({ message: error.userMessages[0] });
                        }
                        else
                        {
                            return context.createError({ message: "Email validation failed for unknown reason." });
                        }
                    }
                })
            ).min(1, "You must add at least one user when creating a client - remember to press enter after typing each email.")
        })
        .required();
};