import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { anyDuplicates } from "../../utils/findDuplicates";

const buildUrlValidator = () => yup.string()
    .max(2048)
    .test((value, context) => {
        if (!value) return true;

        const regEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

        if (!regEx.test(value)) {
            return context.createError({ message: "Must be a valid url." });
        }

        return true;
    })
    .required("Terms & Conditions URL is required.");

const settingsUpdateValidationSchema = yup.object({
    termsAndConditionsUrl: buildUrlValidator(),
    isEmailDomainWhitelisted: yup.boolean(),
    whitelistedEmailDomains: yup.array().of(yup.string())
        .when(["isEmailDomainWhitelisted"], {
            is: true,
            then: yup.array().of(yup.string()
                .matches(/^[a-zA-Z0-9.]*$/, "Invalid email domain format."))
                .min(1, "You must add at least one domain when email domain whitelisting is enabled - remember to press enter after typing each email.")
                .test("unique", "Email domains can't contain duplicates.", values => !anyDuplicates(values || []))
        }),
    gigTerminology: yup.string()
        .required("Gig terminology is required.")
        .max(20, "Gig terminology cannot be greater than 20 characters long."),
    giggedClientTerminology: yup.string()
        .required("Client terminology is required.")
        .max(20, "Client terminology cannot be greater than 20 characters long."),
    talentTerminology: yup.string()
        .required("Talent terminology is required.")
        .max(20, "Talent terminology cannot be greater than 20 characters long."),
    isTalentProfilesPrivate: yup.boolean(),
    isDisputesEnabled: yup.boolean(),
    tagline: yup.string().max(60, "Tagline cannot be longer than 60 characters."),
    logoUrl: yup.string().notRequired().nullable(),
});

export type SettingsUpdateFormValues = {
    termsAndConditionsUrl: string
    isEmailDomainWhitelisted: boolean
    whitelistedEmailDomains?: string[]
    gigTerminology: string
    giggedClientTerminology: string
    talentTerminology: string
    isTalentProfilesPrivate: boolean
    isDisputesEnabled: boolean
    isProposalReviewsEnabled: boolean
    tagline?: string,
    logoUrl?: string,
    theme: string,
};

export const useSettingsUpdateForm = (initialValues?: SettingsUpdateFormValues) => {
    return useForm<SettingsUpdateFormValues>({
        resolver: yupResolver(settingsUpdateValidationSchema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            termsAndConditionsUrl: initialValues?.termsAndConditionsUrl ? initialValues.termsAndConditionsUrl : "",
            isEmailDomainWhitelisted: initialValues?.isEmailDomainWhitelisted ? initialValues.isEmailDomainWhitelisted : false,
            whitelistedEmailDomains: initialValues?.whitelistedEmailDomains ? initialValues.whitelistedEmailDomains : [],
            gigTerminology: initialValues?.gigTerminology,
            giggedClientTerminology: initialValues?.giggedClientTerminology,
            talentTerminology: initialValues?.talentTerminology,
            isTalentProfilesPrivate: initialValues ? initialValues.isTalentProfilesPrivate : false,
            isProposalReviewsEnabled: initialValues ? initialValues.isProposalReviewsEnabled : false,
            tagline: initialValues ? initialValues.tagline : "",
            logoUrl: initialValues ? initialValues.logoUrl : "",
            theme: initialValues?.theme ? initialValues.theme : ""
        }
    });
};