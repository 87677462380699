import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type RejectProposalFormValues = {
    rejectedReason: string;
}

const rejectProposalValidationSchema = yup.object({
    rejectedReason: yup
        .string()
        .max(2000, "Reason cannot be longer than 2000 characters.")
        .required("Reason is required."),
});


export const useRejectProposalForm = () => {
    return useForm<RejectProposalFormValues>({
        resolver: yupResolver(rejectProposalValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            rejectedReason: ""
        }
    });
};