import { ArchiveOutlined, DeleteForeverOutlined } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { UserSummaryDto } from "../../api/models/user";
import { useOrganizationConfig } from "../../api/organization";
import useCurrentUser from "../../hooks/useCurrentUser";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useAppPaths } from "../../Routes";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormCard from "../FormCard";
import FormErrors from "../FormErrors";
import FormTextInput from "../FormTextInput";
import EmailDisplay from "../UserCreateForm/EmailDisplay";
import IsAdminInput from "../UserCreateForm/IsAdminInput";
import { useEditUserForm, UserEditFormValues } from "./UserEditFormValues";

export type UserEditFormProps = {
    onSubmit: (values: UserEditFormValues) => Promise<GigApiFetcherResponse<void>>
    onArchive: () => Promise<GigApiFetcherResponse<void>>
    onUnarchive: () => Promise<GigApiFetcherResponse<void>>
    onDelete: () => Promise<GigApiFetcherResponse<void>>
    initialValues: UserSummaryDto
    isArchiving: boolean
    isUnarchiving: boolean
    isDeleting: boolean
    isSubmitting: boolean
}

const UserEditForm = ({
    onSubmit,
    onArchive,
    onUnarchive,
    onDelete,
    initialValues,
    isArchiving,
    isUnarchiving,
    isDeleting,
    isSubmitting
}: UserEditFormProps) => {
    const currentUser = useCurrentUser();
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const [nameIsEditable, setNameIsEditable] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.users.index);
        }
    });
    const [openDeleteDialog, deleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDelete();
        }
    });
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();

    const currentUserName = `${initialValues?.firstName} ${initialValues?.lastName}`;
    const isEditingCurrentUser = initialValues?.email == currentUser.email;

    const methods = useEditUserForm({
        ...initialValues,
        isAdmin: initialValues.isAdmin ? "true" : "false",
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
            isAdmin: initialValues.isAdmin ? "true" : "false",
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
        });
    }, [methods.reset, initialValues]);

    const {
        formState: { isDirty },
    } = methods;

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.users.index);
        }
    };

    const handleSubmit = async (values: UserEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) {
            setSubmissionError(response.error);
        } else {
            setNameIsEditable(false);
        }
    };

    const handleArchive = async () => {
        setSubmissionError(undefined);

        const response = await onArchive();

        if (!response.success) setSubmissionError(response.error);
    };

    const handleUnarchive = async () => {
        setSubmissionError(undefined);

        const response = await onUnarchive();

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDelete = async () => {
        setSubmissionError(undefined);

        const response = await onDelete();

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.users.index);
    };

    const handleEditNameButton = () => {
        setNameIsEditable(!nameIsEditable);
        methods.resetField("firstName");
        methods.resetField("lastName");
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    noValidate
                >
                    <FormCard
                        withoutTitleUnderline={nameIsEditable}
                        onClose={handleCloseButton}
                        title={!nameIsEditable ? (
                            <span className="hover:opacity-[50%] h-[50px]" onClick={() => setNameIsEditable(!nameIsEditable)}>
                                {currentUserName}
                            </span>
                        ) : (
                            <Box sx={isLargeScreen ?
                                { display: "flex", flexDirection: "flex-row", paddingBottom: "none", marginBottom: "none" } :
                                { display: "flex", flexDirection: "column", paddingBottom: "none", marginBottom: "none" }
                            }>
                                <FormTextInput
                                    name="firstName"
                                    label="First name"
                                    disabled={isSubmitting}
                                    variant="standard"
                                    sx={isLargeScreen ? { backgroundColor: theme.palette.background.paper, marginRight: 2 } : { backgroundColor: theme.palette.background.paper, marginBottom: 2 }}
                                />
                                <FormTextInput
                                    name="lastName"
                                    label="Last name"
                                    disabled={isSubmitting}
                                    variant="standard"
                                    sx={{ backgroundColor: theme.palette.background.paper, marginRight: 2 }}
                                />
                            </Box>
                        )}
                        editTitleButton={
                            <Box sx={{ alignItems: "start", marginLeft: 1, maxHeight: "3rem" }}>
                                <Button
                                    onClick={handleEditNameButton}
                                    variant="text"
                                >
                                    <Typography>
                                        {nameIsEditable ?
                                            "Cancel" :
                                            <ModeEditOutlineOutlinedIcon color="secondary" />
                                        }
                                    </Typography>
                                </Button>
                            </Box>
                        }>
                        <Box sx={{
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(3),
                            }
                        }}>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                User ID: <Typography component="span">{initialValues.id}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                {giggedClientTerminology}: {initialValues.giggedClientId ? (
                                    <Link to={appPaths.clients.edit(initialValues.giggedClientId)}>
                                        <Typography component="span" sx={{ textDecoration: "underline" }}>
                                            {initialValues.giggedClientName}
                                        </Typography>
                                    </Link>
                                ) : (
                                    <Typography component="span">-</Typography>
                                )}
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Is {talentTerminology}?: <Typography component="span">
                                    {initialValues.talentId ? "Yes" : "No"}
                                </Typography>
                            </Typography>
                            <EmailDisplay email={initialValues.email} />
                            <IsAdminInput isCurrentUser={isEditingCurrentUser} disabled={isEditingCurrentUser ?? isSubmitting} />
                            <FormErrors messages={submissionError?.userMessages} />
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end", gridGap: "1rem" } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "space-between" }
                            }>
                                <LoadingButton disabled={isDeleting || isSubmitting} startIcon={<DeleteForeverOutlined sx={isDeleting ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isDeleting} color="secondary" onClick={openDeleteDialog}>Delete</LoadingButton>
                                {initialValues.isArchived ? (
                                    <LoadingButton disabled={isArchiving || isSubmitting} startIcon={<ArchiveOutlined sx={isUnarchiving ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isUnarchiving} color="secondary" onClick={handleUnarchive}>Unarchive</LoadingButton>
                                ) : (
                                    <LoadingButton disabled={isUnarchiving || isSubmitting} startIcon={<ArchiveOutlined sx={isArchiving ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isArchiving} color="secondary" onClick={handleArchive}>Archive</LoadingButton>
                                )}
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    type="submit"
                                    disabled={!isDirty}
                                    loading={isSubmitting}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogProps}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <ConfirmDialogBox
                {...deleteDialogProps}
                message="Are you sure you want to delete this user? This action cannot be undone."
                warningMessage={initialValues.hasAnyOngoingGigs ? "This user has one or more ongoing gigs. Please make sure to consider any outstanding payments before deleting the user." : undefined}
                confirmButtonText="Delete"
            />
        </>
    );
};

export default UserEditForm;
