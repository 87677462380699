import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { AddTalentBadgeDto, DeleteTalentBadgeDto, TalentDetailsDto, UpdateTalentDto } from "./models/talent";

export const useUpdateTalent = () => useAuthenticatedGigApiFetcher<UpdateTalentDto, void, { talentId: string, dto: UpdateTalentDto }>("PUT",
    ({ talentId, dto }) => ({
        path: `api/talent/${talentId}`,
        body: dto
    }));

export const useAddTalentBadge = () => useAuthenticatedGigApiFetcher<AddTalentBadgeDto, void, { talentId: string, dto: AddTalentBadgeDto }>("POST",
    ({ talentId, dto }) => ({
        path: `api/talent/${talentId}/badges/${dto.badgeId}`
    }));

export const useDeleteTalentBadge = () => useAuthenticatedGigApiFetcher<DeleteTalentBadgeDto, void, { talentId: string, dto: DeleteTalentBadgeDto }>("DELETE", ({ talentId, dto }) => ({
    path: `api/talent/${talentId}/badges/${dto.badgeId}`
}));

export type UseTalentReturn<TTalentId extends string | undefined> = TTalentId extends undefined ?
    { talent: undefined } :
    {
        talent?: TalentDetailsDto
        isLoading: boolean
        error?: ApiError
        updateTalent: (dto: UpdateTalentDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingTalent: boolean
        addTalentBadge: (dto: AddTalentBadgeDto) => Promise<GigApiFetcherResponse<void>>
        isAddingTalentBadge: boolean
        deleteTalentBadge: (dto: AddTalentBadgeDto) => Promise<GigApiFetcherResponse<void>>
        isDeletingTalentBadge: boolean
    }

export const useTalent = <TTalentId extends string | undefined>(talentId: TTalentId): UseTalentReturn<TTalentId> => {
    const url = `api/talents/${talentId}`;

    const apiCall = useGiggedApiSWR<TalentDetailsDto>(url);

    const [updateTalent, isUpdatingTalent] = useUpdateTalent();
    const [addTalentBadge, isAddingTalentBadge] = useAddTalentBadge();
    const [deleteTalentBadge, isDeletingTalentBadge] = useDeleteTalentBadge();

    if (talentId === undefined) return { talent: undefined } as UseTalentReturn<TTalentId>;

    return {
        talent: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateTalent: async (dto: UpdateTalentDto) => {
            const response = await updateTalent({ talentId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingTalent,
        addTalentBadge: async (dto: AddTalentBadgeDto) => {
            const response = await addTalentBadge({ talentId, dto });

            if (response.success) {
                apiCall.mutate();
            }
            return response;
        },
        isAddingTalentBadge,
        deleteTalentBadge: async (dto: DeleteTalentBadgeDto) => {
            const response = await deleteTalentBadge({ talentId, dto });

            if (response.success) {
                apiCall.mutate();
            }
            return response;
        },
        isDeletingTalentBadge,
    } as UseTalentReturn<TTalentId>;
};