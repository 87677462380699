import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { expectedDurationOptions, GigSummary, initialStageOptions } from "../../api/models/gig";
import { useOrganizationConfig } from "../../api/organization";
import FormCard from "../FormCard";
import FormDateInput from "../FormDateInput";
import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import FormTextAreaInput from "../FormTextAreaInput";
import ToggleButton from "../ToggleButton";
import GigSkillsInput from "../GigSkillsInput";
import { GigEditFormValues, useEditGigForm } from "./GigEditFormValues";
import Loader from "../Loader";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormErrors from "../FormErrors";
import ApiError from "../../api/common/apiError";
import { addDays, differenceInBusinessDays } from "date-fns";

export type GigEditFormProps = {
    gig: GigSummary
    loading: boolean
    submissionError?: ApiError
    handleSaveChanges: (values: GigEditFormValues) => Promise<void>
    handleCloseEditGigPage: () => void
}

const determineExpectedDurationId = (numberOfDays: number) => {
    if (numberOfDays < 7) return 0; // Less than a week
    else if (numberOfDays < 30) return 1; // Less than a month
    else if (numberOfDays <= 30) return 2; // 1 month
    else if (numberOfDays <= 90) return 3; // 2-3 months
    else if (numberOfDays <= 180) return 4; // 3-6 months
    else return 5; // More than 6 months
};

const GigEditForm = ({
    gig,
    loading,
    submissionError,
    handleSaveChanges,
    handleCloseEditGigPage
}: GigEditFormProps) => {
    const [gigLoaded, setGigLoaded] = useState(false);
    const { gigTerminology, organizationConfig } = useOrganizationConfig();
    const methods = useEditGigForm(gig);
    const isLargeScreen = useIsLargeScreen();
    const { control, reset, watch, setValue, trigger, clearErrors, handleSubmit, formState, formState: { dirtyFields } } = methods;
    const isDirtyAlt = Object.keys(dirtyFields).length > 0;
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCloseEditGigPage();
        }
    });

    useEffect(() => {
        if (gigLoaded) return;

        reset(gig);

        if (gig) {
            setGigLoaded(true);
        }

    }, [gig, reset, gigLoaded]);

    const handleDiscardEditGig = () => {
        if (isDirtyAlt) {
            openCancelDialog();
        } else {
            handleCloseEditGigPage();
        }
    };

    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigEditFormValues, "skills">({
        control: control,
        name: "skills",
        keyName: "id",
    });

    const { skills, isTimeAndMaterial, startDate, deadlineDate, dailyRate, numberOfDays } = watch();

    useEffect(() => {    
        if (isTimeAndMaterial) {
            let shouldTriggerValidation = false;
    
            // Calculating number of days
            if (startDate && deadlineDate) {
                const newNumberOfDays = differenceInBusinessDays(new Date(deadlineDate), new Date(startDate)) + 1;
                setValue("numberOfDays", newNumberOfDays, { shouldValidate: true });
                setValue("expectedDurationId", determineExpectedDurationId(newNumberOfDays), { shouldValidate: true });
                shouldTriggerValidation = true;
            }
    
            // Calculating budget
            if (dailyRate && numberOfDays) {
                setValue("budget", dailyRate * numberOfDays, { shouldValidate: true });
                shouldTriggerValidation = true;
            }
    
            // Optionally, trigger validation for specific fields if needed
            if (shouldTriggerValidation) {
                trigger(["numberOfDays", "dailyRate", "budget"]);
            }
        } else {
            // Resetting fields when isTimeAndMaterial is false
            setValue("numberOfDays", null);
            setValue("dailyRate", null);
            setValue("budget", null);
            clearErrors(["numberOfDays", "dailyRate", "budget"]);
        }
    }, [isTimeAndMaterial, startDate, deadlineDate, dailyRate, numberOfDays]);

    if (!gig) return <Loader />;    

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleSaveChanges)}
                    noValidate
                >
                    <FormCard
                        title={`Edit ${gigTerminology}`}
                    >
                        <Box className="space-y-4">
                            <Box className="space-y-2">
                                <FormInputLabel required>{`${gigTerminology} title`}</FormInputLabel>
                                <FormTextInput
                                    required
                                    name="title"
                                />
                            </Box>
                            <FormTextAreaInput 
                                name="description" 
                                label={`${gigTerminology} description`} 
                                required
                            />
                            {organizationConfig?.isPaymentsEnabled && ( 
                                <div className="mb-4">
                                    <Controller
                                        name="isTimeAndMaterial"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <ToggleButton
                                                label={isTimeAndMaterial ? "Time and material" : "Outcome based"}
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        )}
                                    />
                                </div>
                            )}                            
                            <Box className="md:space-x-8 space-y-4 md:space-y-0 flex flex-col md:flex-row">
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="startDate"
                                                label="Start date"
                                                value={value}
                                                onChange={onChange}
                                                minDate={new Date()}
                                                error={formState.errors.startDate}
                                                required
                                                shouldDisableWeekends={isTimeAndMaterial}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="deadlineDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="deadlineDate"
                                                label="Deadline (optional)"
                                                value={value}
                                                onChange={onChange}
                                                error={formState.errors.deadlineDate}
                                                minDate={addDays(methods.watch().startDate as Date, 1)}
                                                shouldDisableWeekends={isTimeAndMaterial}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box className="md:space-x-8 space-y-4 md:space-y-0 flex flex-col md:flex-row">
                                <Box className="flex flex-col flex-1 space-y-2">
                                    {isTimeAndMaterial ? (
                                        <>
                                            <FormInputLabel required>Number of days</FormInputLabel>
                                            <FormTextInput
                                                multiline
                                                required
                                                type="number"
                                                name="numberOfDays"
                                                disabled
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FormInputLabel required>Duration</FormInputLabel>
                                            <Controller
                                                name="expectedDurationId"
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        variant="outlined"
                                                        color="secondary"
                                                    >
                                                        {expectedDurationOptions.map(option => (
                                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </>
                                    )}
                                </Box>
                                <Box className="flex flex-col flex-1 space-y-2">
                                    <FormInputLabel required>{`Stage of the ${gigTerminology.toLowerCase()}`}</FormInputLabel>
                                    <Controller
                                        name="initialStageId"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                {initialStageOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </Box>
                            </Box>
                            {organizationConfig?.isPaymentsEnabled && (
                                <>
                                    <Box className="space-y-2">
                                        {isTimeAndMaterial ? (
                                            <div className="flex space-x-4">
                                                <div className="w-1/2 space-y-2">
                                                    <FormInputLabel required>{`${gigTerminology} daily rate (£)`}</FormInputLabel>
                                                    <FormTextInput
                                                        multiline
                                                        required
                                                        type="number"
                                                        name="dailyRate"
                                                    />
                                                </div>
                                                <div className="w-1/2 space-y-2">
                                                    <FormInputLabel required>{`${gigTerminology} budget (£)`}</FormInputLabel>
                                                    <FormTextInput
                                                        multiline
                                                        required
                                                        type="number"
                                                        name="budget"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <FormInputLabel required>{`${gigTerminology} budget (£)`}</FormInputLabel>
                                                <FormTextInput
                                                    multiline
                                                    required
                                                    type="number"
                                                    name="budget"
                                                />
                                            </>
                                        )}
                                    </Box>
                                </>
                            )}
                            <GigSkillsInput
                                addSkill={appendSkill}
                                removeSkill={removeSkill}
                                skills={skills}
                                error={formState.errors.skills}
                                showError={formState.submitCount > 0}
                                required
                            />
                            <Controller
                                name="isCompletedRemotely"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Can be completed remotely?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            <Controller
                                name="isComplianceCheckRequired"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Compliance check required?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            <FormErrors messages={submissionError?.userMessages} />

                            <Box className="flex flex-col-reverse md:flex-row md:justify-between md:items-center !mt-8">
                                <Button
                                    color="secondary"
                                    variant="text"
                                    disabled={loading}
                                    onClick={handleDiscardEditGig}
                                >
                                    Discard changes
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirtyAlt || loading ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    type="submit"
                                    color="primary"
                                    loading={loading}
                                    disabled={!isDirtyAlt}
                                    sx={!isLargeScreen ? { marginBottom: 2 } : { marginBottom: 0 }}
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default GigEditForm;