export type TalentWithdrawalSummaryDto = {
    id: string
    talentId: string
    talentFirstName: string
    talentLastName: string
    talentTradingName?: string
    statusId: number
    amount: number
    createdAt: string
}

export type TalentWithdrawalDetailsDto = TalentWithdrawalSummaryDto & {
    talentAvailableBalance: number
}

export type UpdateTalentWithdrawalDto = {
    statusId: number
}

export const talentWithdrawalStatus = {
    pending: 1,
    complete: 2,
    rejectedOrCancelled: 3,
};

export const talentWithdrawalStatusOptions = [
    { label: "Pending", value: 1 },
    { label: "Complete", value: 2 },
    { label: "Rejected / Cancelled", value: 3 },
];