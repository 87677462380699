import { Box, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { gigStatuses } from "../../api/models/gig";
import FormCard from "../FormCard";
import Loader from "../Loader";
import { LoadingButton } from "@mui/lab";
import ApiError from "../../api/common/apiError";
import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { useOrganizationConfig } from "../../api/organization";
import { talentWithdrawalStatus, talentWithdrawalStatusOptions, TalentWithdrawalDetailsDto } from "../../api/models/talentWithdrawal";
import MoneyDisplay from "../MoneyDisplay";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../ConfirmDialogBox";
import { useAppPaths } from "../../Routes";
import { useTalent } from "../../api/talent";

export type GigStatus = keyof typeof gigStatuses;

export type TalentWithdrawalViewPageProps = {
    withdrawal: TalentWithdrawalDetailsDto
    onUpdateStatus: (statusId: number) => Promise<GigApiFetcherResponse<void>>
    isUpdatingStatus: boolean
}

const TalentWithdrawalViewPage = ({
    withdrawal,
    onUpdateStatus,
    isUpdatingStatus
}: TalentWithdrawalViewPageProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { talentTerminology } = useOrganizationConfig();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [newStatusId, setNewStatusId] = useState<number>(-1);
    const appPaths = useAppPaths();
    const { talent } = useTalent(withdrawal?.talentId);

    const handleCloseButton = () => {
        navigate(-1);
    };

    const makeUpdateStatusHandler = (statusId: number) => () => {
        setNewStatusId(statusId);
        openConfirmDialog();
    };

    const handleConfirmUpdateStatus = async () => {
        setSubmissionError(undefined);

        const response = await onUpdateStatus(newStatusId);

        if (!response.success) setSubmissionError(response.error);
    };

    const [openConfirmDialog, confirmDialogProps] = useConfirmDialogBoxState({
        onConfirm: handleConfirmUpdateStatus,
    });

    if (!talent) return <Loader />;

    const talentName = withdrawal.talentTradingName || `${withdrawal.talentFirstName} ${withdrawal.talentLastName}`;
    const withdrawalStatusLabel = talentWithdrawalStatusOptions[withdrawal.statusId - 1].label;

    return (
        <>
            <FormCard title={`Withdrawal for ${talentName}`} onClose={handleCloseButton}>
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{withdrawal.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology}: <Typography sx={{ marginLeft: "8px" }} component="span">{talentName}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology} Available Balance: <Typography sx={{ marginLeft: "8px" }} component="span"><MoneyDisplay value={withdrawal.talentAvailableBalance} /></Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Amount: <Typography sx={{ marginLeft: "8px" }} component="span"><MoneyDisplay value={withdrawal.amount} /></Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: <Typography sx={{ marginLeft: "8px" }} component="span">{withdrawalStatusLabel}</Typography>
                    </Typography>
                    {talent?.hasEnteredPayoneerDetails && (
                        <Typography fontWeight="bold" component="label" sx={{ display: "block", textDecoration: "underline", cursor: "pointer" }}>
                            <Link to={appPaths.talents.payoneerDetails(withdrawal.talentId)}>
                                View Payoneer Details
                            </Link>
                        </Typography>
                    )}

                    <FormErrors messages={submissionError?.userMessages} />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <LoadingButton
                            disabled={isUpdatingStatus || withdrawal.statusId != talentWithdrawalStatus.pending}
                            loading={isUpdatingStatus}
                            color="secondary"
                            onClick={makeUpdateStatusHandler(talentWithdrawalStatus.rejectedOrCancelled)}
                        >
                            Reject / Cancel
                        </LoadingButton>
                        <LoadingButton
                            disabled={isUpdatingStatus || withdrawal.statusId != talentWithdrawalStatus.pending}
                            loading={isUpdatingStatus}
                            color="secondary"
                            onClick={makeUpdateStatusHandler(talentWithdrawalStatus.complete)}
                        >
                            Complete
                        </LoadingButton>
                    </Box>
                </Box>
            </FormCard>
            <ConfirmDialogBox
                {...confirmDialogProps}
                message={`Are you sure you want to ${newStatusId === talentWithdrawalStatus.complete ? "Complete" : "Reject / Cancel"} this withdrawal?`}
                isProcessingConfirm={isUpdatingStatus}
                cancelButtonText="Back"
                sx={{ maxWidth: "20rem" }}
            />
        </>
    );
};

export default TalentWithdrawalViewPage;