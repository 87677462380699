import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiProposalStatisticsDto } from "../models/dashboardStatistics/proposalStatistics";

export type UseProposalStatisticsReturn =
    UseGiggedApiSWRReturn<ApiProposalStatisticsDto> &
    {
        statistics?: ApiProposalStatisticsDto
        isLoading: boolean
    }

export const useProposalStatistics = (): UseProposalStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/proposals";

    const apiCall = useGiggedApiSWR<ApiProposalStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};