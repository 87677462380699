import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { TalentBankDetailsDto } from "./models/talentBankDetails";

export type UseTalentBankDetailsReturn<TTalentId extends string | undefined> = TTalentId extends undefined ? 
    { talentBankAccount: undefined } : 
    {
        talentBankDetails?: TalentBankDetailsDto
        isLoading: boolean
    }

export const useTalentBankDetails = <TTalentId extends string | undefined>(talentId: TTalentId): UseTalentBankDetailsReturn<TTalentId> => {
    const url = `api/talent/${talentId}/bank-account`;

    const apiCall = useGiggedApiSWR<TalentBankDetailsDto>(url);


    if (talentId === undefined) return { talentBankDetails: undefined } as UseTalentBankDetailsReturn<TTalentId>;

    return {
        ...apiCall,
        talentBankDetails: apiCall.data, 
        isLoading: apiCall.isLoading,
    } as unknown as UseTalentBankDetailsReturn<TTalentId>;
};