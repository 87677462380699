import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useNavigate } from "react-router-dom";

import { useProposalMilestone } from "../../api/proposalMilestone";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useAppPaths, useProposalMilestoneId } from "../../Routes";
import FormCard from "../FormCard";
import FormContainer from "../FormContainer";
import FormDateInput from "../FormDateInput";
import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import Loader from "../Loader";
import { ProposalMilestoneEditFormValues, useEditProposalMilestoneForm } from "./ProposalMilestoneEditFormValues";
import ApiError from "../../api/common/apiError";
import { successToast } from "../../toast";
import FormErrors from "../FormErrors";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../ConfirmDialogBox";
import { useOrganizationConfig } from "../../api/organization";

const ProposalMilestoneEditForm = () => {
    const { talentTerminology, giggedClientTerminology } = useOrganizationConfig();
    const proposalMilestoneId = useProposalMilestoneId();
    const { proposalMilestone, isLoading, updateProposalMilestone, isUpdatingProposalMilestone } = useProposalMilestone(proposalMilestoneId);
    const methods = useEditProposalMilestoneForm(proposalMilestone);
    const { formState: { isDirty, errors }, reset, handleSubmit, control } = methods;
    const [proposalMilestoneIsLoaded, setProposalMilestoneIsLoaded] = useState(false);
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.milestones.view(proposalMilestoneId));
        }
    });

    useEffect(() => {
        if (proposalMilestoneIsLoaded) return;

        reset({
            description: proposalMilestone?.description ?? "",
            amount: proposalMilestone?.amount ?? 0,
            discountAmount: proposalMilestone?.discountAmount ?? 0,
            clientPaymentCollectedDate: proposalMilestone?.clientPaymentCollectedDate ?? null,
            clientPaymentPayPalId: proposalMilestone?.clientPaymentPayPalId ?? "",
            clientPaymentStripePaymentIntentId: proposalMilestone?.clientPaymentStripePaymentIntentId ?? "",
            clientPaymentStripeChargeId: proposalMilestone?.clientPaymentStripeChargeId ?? "",
            talentStripeTransferId: proposalMilestone?.talentStripeTransferId ?? "",
            dateCompleted: proposalMilestone?.dateCompleted ?? null,
            stripeInvoicePaidDate: proposalMilestone?.stripeInvoicePaidDate ?? null
        });

        if (proposalMilestone) setProposalMilestoneIsLoaded(true);

    }, [proposalMilestone, reset, proposalMilestoneIsLoaded]);

    const onSubmit = async (values: ProposalMilestoneEditFormValues) => {

        const response = await updateProposalMilestone(values as any);

        if (response.success) {
            successToast("Milestone successfully updated.");
            navigate(appPaths.milestones.view(proposalMilestoneId));
        }

        return response;
    };

    const handleSaveChanges = async (values: ProposalMilestoneEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDiscardEditMilestone = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.milestones.view(proposalMilestoneId));
        }
    };

    if (!proposalMilestone) return <Loader />;

    return (
        <>
            <FormContainer>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(handleSaveChanges)}
                        noValidate
                    >
                        <FormCard
                            title="Edit milestone"
                        >
                            <Box className="space-y-4">
                                <Box className="space-y-2">
                                    <FormInputLabel>Milestone description</FormInputLabel>
                                    <FormTextInput
                                        required
                                        multiline
                                        name="description"
                                    />
                                </Box>
                                <Box className="space-y-2">
                                    <FormInputLabel>Milestone amount (£)</FormInputLabel>
                                    <FormTextInput
                                        required
                                        multiline
                                        type="number"
                                        name="amount"
                                    />
                                </Box>
                                <Box className="space-y-2">
                                    <FormInputLabel>Milestone discount amount (£)</FormInputLabel>
                                    <FormTextInput
                                        required
                                        multiline
                                        type="number"
                                        name="discountAmount"
                                    />
                                </Box>
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="dateCompleted"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="dateCompleted"
                                                label="Date milestone completed"
                                                value={value}
                                                onChange={onChange}
                                                error={errors.clientPaymentCollectedDate}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="clientPaymentCollectedDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="clientPaymentCollectedDate"
                                                label={`${giggedClientTerminology} payment collected date`}
                                                value={value}
                                                onChange={onChange}
                                                error={errors.clientPaymentCollectedDate}
                                            />
                                        )}
                                    />
                                </Box>
                                {!proposalMilestone.stripeInvoiceId && (
                                    <>
                                        <Box className="space-y-2">
                                            <FormInputLabel>{`${giggedClientTerminology} Stripe payment intent ID`}</FormInputLabel>
                                            <FormTextInput
                                                required
                                                name="clientPaymentStripePaymentIntentId"
                                            />
                                        </Box>
                                        <Box className="space-y-2">
                                            <FormInputLabel>{`${giggedClientTerminology} Stripe charge ID`}</FormInputLabel>
                                            <FormTextInput
                                                required
                                                name="clientPaymentStripeChargeId"
                                            />
                                        </Box>
                                        <Box className="space-y-2">
                                            <FormInputLabel>{`${giggedClientTerminology} PayPal ID`}</FormInputLabel>
                                            <FormTextInput
                                                required
                                                name="clientPaymentPayPalId"
                                            />
                                        </Box>
                                    </>
                                )}
                                <Box className="space-y-2">
                                    <FormInputLabel>{`${talentTerminology} Stripe transfer ID`}</FormInputLabel>
                                    <FormTextInput
                                        required
                                        name="talentStripeTransferId"
                                    />
                                </Box>
                                
                                {proposalMilestone.stripeInvoiceId && (
                                    <Box className="flex flex-col flex-1">
                                        <Controller
                                            name="stripeInvoicePaidDate"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <FormDateInput
                                                    name="stripeInvoicePaidDate"
                                                    label={`${giggedClientTerminology} PO Stripe invoice paid date`}
                                                    value={value}
                                                    onChange={onChange}
                                                    error={errors.stripeInvoicePaidDate}
                                                />
                                            )}
                                        />
                                    </Box>
                                )}
                            </Box>

                            <FormErrors messages={submissionError?.userMessages} />

                            <Box className="flex flex-col-reverse md:flex-row md:justify-between md:items-center !mt-8">
                                <Button
                                    color="secondary"
                                    variant="text"
                                    disabled={isLoading || isUpdatingProposalMilestone}
                                    onClick={handleDiscardEditMilestone}
                                >
                                    Discard changes
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    startIcon={
                                        <SaveOutlinedIcon
                                            sx={!isDirty ||
                                                isLoading ||
                                                isUpdatingProposalMilestone ?
                                                { opacity: "26%" } :
                                                { opacity: "100%" }}
                                        />
                                    }
                                    type="submit"
                                    color="primary"
                                    loading={isLoading || isUpdatingProposalMilestone}
                                    disabled={!isDirty}
                                    sx={!isLargeScreen ? { marginBottom: 2 } : { marginBottom: 0 }}
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </FormCard>
                    </form>
                </FormProvider>
            </FormContainer>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default ProposalMilestoneEditForm;