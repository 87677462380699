import * as yup from "yup";
import { AnyObject } from "yup/lib/types";

export const hasNoMoreThan2DecimalPlaces = (value: number | undefined, context: yup.TestContext<AnyObject>) => {
    if (!value) return true;

    const regEx = /^\d+(\.\d{1,2})?$/;

    if (!regEx.test(value.toString())) {
        return context.createError({ message: "Must have no more than 2 digits after decimal point." });
    }

    return true;
};