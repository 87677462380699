import { useOrganizationConfig } from "../../api/organization";
import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import { ApiGigStatisticsDto } from "../../api/models/dashboardStatistics/gigStatistics";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";
import MoneyDisplay from "../../components/MoneyDisplay";

export type DashboardGigsSectionProps = {
    statistics?: ApiGigStatisticsDto
}

const DashboardGigsSection = ({
    statistics
}: DashboardGigsSectionProps) => {
    const { gigTerminologyPlural, gigTerminology, organizationConfig } = useOrganizationConfig();

    return (
        <DashboardSection
            title={gigTerminologyPlural}
        >
            <div className="flex flex-col space-y-2 w-full">
                <DashboardCard
                    title={`${gigTerminology} conversion rate`}
                    statistic={`${statistics?.gigConversionRate}%`}
                    tooltip={dashboardOverviewTooltips.gigConversion(gigTerminologyPlural)}
                />
                <DashboardSubSection
                    title={`Total ${gigTerminologyPlural.toLowerCase()}`}
                    tooltip={dashboardOverviewTooltips.gigsTotals(gigTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Total"
                            statistic={statistics?.createdTotal || 0}
                            tooltip={dashboardOverviewTooltips.totalGigs(gigTerminologyPlural)}
                        />
                        <DashboardCard
                            title="Posted"
                            statistic={statistics?.countOfAllPostedGigs || 0}
                            tooltip={dashboardOverviewTooltips.totalGigsPosted(gigTerminologyPlural)}
                        />
                        <DashboardCard
                            title="Hired"
                            statistic={statistics?.countOfAllHiredGigs || 0}
                            tooltip={dashboardOverviewTooltips.totalGigsHired(gigTerminologyPlural)}
                        />
                        <DashboardCard
                            title="Completed"
                            statistic={statistics?.countOfAllCompletedGigs || 0}
                            tooltip={dashboardOverviewTooltips.totalGigsCompleted(gigTerminologyPlural)}
                        />
                        <DashboardCard
                            title="Cancelled"
                            statistic={statistics?.countOfAllCancelledGigs || 0}
                            tooltip={dashboardOverviewTooltips.totalGigsCancelled(gigTerminologyPlural)}
                        />
                        <DashboardCard
                            title="Uptaken"
                            statistic={statistics?.countOfAllUptakenGigs || 0}
                            tooltip={dashboardOverviewTooltips.totalGigsUptaken(gigTerminologyPlural)}
                        />
                        <DashboardCard
                            title="Idle"
                            statistic={statistics?.countOfAllIdle || 0}
                            tooltip={dashboardOverviewTooltips.totalGigsIdle(gigTerminologyPlural)}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title={`Active ${gigTerminologyPlural.toLowerCase()}`}
                    tooltip={dashboardOverviewTooltips.activeGigs(gigTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Draft"
                            statistic={statistics?.countOfActiveDraftGigs || 0}
                        />
                        <DashboardCard
                            title="Posted"
                            statistic={statistics?.countOfActivePostedGigs || 0}
                        />
                        <DashboardCard
                            title="Hired"
                            statistic={statistics?.countOfActiveHiredGigs || 0}
                        />
                        <DashboardCard
                            title="Completed"
                            statistic={statistics?.countOfActiveCompletedGigs || 0}
                        />
                        <DashboardCard
                            title="Cancelled"
                            statistic={statistics?.countOfActiveCancelledGigs || 0}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="Created totals"
                    tooltip={dashboardOverviewTooltips.gigsCreated(gigTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={`${statistics?.createdLast7Days || 0} (+${statistics?.percentageIncreaseLast7Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={`${statistics?.createdLast14Days || 0} (+${statistics?.percentageIncreaseLast14Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={`${statistics?.createdLast30Days || 0} (+${statistics?.percentageIncreaseLast30Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={`${statistics?.createdLast3Months || 0} (+${statistics?.percentageIncreaseLast3Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={`${statistics?.createdLast6Months || 0} (+${statistics?.percentageIncreaseLast6Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={`${statistics?.createdLast9Months || 0} (+${statistics?.percentageIncreaseLast9Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={`${statistics?.createdLast12Months || 0} (+${statistics?.percentageIncreaseLast12Months || 0}%)`}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="Posted totals"
                    tooltip={dashboardOverviewTooltips.gigsPosted(gigTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={statistics?.postedLast7Days || 0}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={statistics?.postedLast14Days || 0}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={statistics?.postedLast30Days || 0}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={statistics?.postedLast3Months || 0}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={statistics?.postedLast6Months || 0}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={statistics?.postedLast9Months || 0}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={statistics?.postedLast12Months || 0}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="Completed totals"
                    tooltip={dashboardOverviewTooltips.gigsCompleted(gigTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={statistics?.completedLast7Days || 0}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={statistics?.completedLast14Days || 0}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={statistics?.completedLast30Days || 0}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={statistics?.completedLast3Months || 0}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={statistics?.completedLast6Months || 0}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={statistics?.completedLast9Months || 0}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={statistics?.completedLast12Months || 0}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="Cancelled totals"
                    tooltip={dashboardOverviewTooltips.gigsCancelled(gigTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={statistics?.cancelledLast7Days || 0}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={statistics?.cancelledLast14Days || 0}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={statistics?.cancelledLast30Days || 0}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={statistics?.cancelledLast3Months || 0}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={statistics?.cancelledLast6Months || 0}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={statistics?.cancelledLast9Months || 0}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={statistics?.cancelledLast12Months || 0}
                        />
                    </div>
                </DashboardSubSection>
                {organizationConfig?.isPaymentsEnabled && (
                    <>
                        <DashboardSubSection
                            title={`${gigTerminology} values`}
                        >
                            <div className="flex flex-row space-x-2">
                                <DashboardCard
                                    title="All gigs total"
                                    statistic={<MoneyDisplay value={statistics?.gigsValue.totalValueOfAllGigs || 0} />}
                                    tooltip={dashboardOverviewTooltips.gigValuesAllTotal(gigTerminologyPlural)}
                                />
                                <DashboardCard
                                    title="All gigs average"
                                    statistic={<MoneyDisplay value={statistics?.gigsValue.averageValueOfAllGigs || 0} />}
                                    tooltip={dashboardOverviewTooltips.gigValuesAverageTotal(gigTerminologyPlural)}

                                />
                                <DashboardCard
                                    title="Uptaken gigs total"
                                    statistic={<MoneyDisplay value={statistics?.gigsValue.totalValueOfGigsUptaken || 0} />}
                                    tooltip={dashboardOverviewTooltips.gigValuesAllUptaken(gigTerminologyPlural)}
                                />
                                <DashboardCard
                                    title="Uptaken gigs average"
                                    statistic={<MoneyDisplay value={statistics?.gigsValue.averageValueOfGigsUptaken || 0} />}
                                    tooltip={dashboardOverviewTooltips.gigValuesAverageUptaken(gigTerminologyPlural)}
                                />
                            </div>
                        </DashboardSubSection>
                        <DashboardSubSection
                            title="Organisation earnings"
                        >
                            <div className="flex flex-row space-x-2">
                                <DashboardCard
                                    title="Total earnings"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.totalEarnings || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsTotal(gigTerminologyPlural)}
                                />
                                <DashboardCard
                                    title="Average earnings"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.averageEarnings || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsAverage(gigTerminologyPlural)}
                                />
                                <DashboardCard
                                    title="Projected total earnings (idle/posted)"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.projectedTotalEarningsOfIdlePosted || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsProjectedTotalAllIdlePosted(gigTerminologyPlural)}
                                />
                                <DashboardCard
                                    title="Projected average earnings (idle/posted)"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.projectedAverageEarningsOfIdlePosted || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsProjectedAverageAllIdlePosted(gigTerminologyPlural, gigTerminology)}
                                />
                                <DashboardCard
                                    title="Projected total earnings (idle/draft)"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.projectedTotalEarningsOfIdleDraft || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsProjectedTotalAllIdleDraft(gigTerminologyPlural)}

                                />
                                <DashboardCard
                                    title="Projected average earnings (idle/draft)"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.projectedAverageEarningsOfIdleDraft || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsProjectedAverageAllIdleDraft(gigTerminologyPlural, gigTerminology)}

                                />
                                <DashboardCard
                                    title="Projected total earnings (idle)"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.projectedTotalEarningsOfAllIdle || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsProjectedTotalAllIdle(gigTerminologyPlural)}
                                />
                                <DashboardCard
                                    title="Projected average earnings (idle)"
                                    statistic={<MoneyDisplay value={statistics?.organizationEarnings.projectedAverageEarningsOfAllIdle || 0} />}
                                    tooltip={dashboardOverviewTooltips.earningsProjectedAverageAllIdle(gigTerminologyPlural, gigTerminology)}

                                />
                            </div>
                        </DashboardSubSection>
                    </>
                )}
                <DashboardSubSection
                    title={`${gigTerminologyPlural} by skill`}
                    tooltip={`Number of ${gigTerminologyPlural.toLowerCase()} requiring the specified skill`}
                >
                    {statistics?.gigSkillsByCount && statistics?.gigSkillsByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics?.gigSkillsByCount.map(skill => (
                                <DashboardCard
                                    key={skill.skillName}
                                    title={skill.skillName}
                                    statistic={skill.skillCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardGigsSection;