import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { gigStatuses } from "../../api/models/gig";
import FormCard from "../FormCard";
import Loader from "../Loader";
import { useAppPaths } from "../../Routes";
import CreatedByDisplay from "../CreatedByDisplay";
import { ProposalDetails, RejectProposalDto, proposalStatusLabels, proposalStatuses } from "../../api/models/proposal";
import { LoadingButton } from "@mui/lab";
import ApiError from "../../api/common/apiError";
import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { useOrganizationConfig } from "../../api/organization";
import useDialogBoxState from "../DialogBox/useDialogBoxState";
import DialogBox from "../DialogBox/DialogBox";
import RejectProposalForm from "./RejectProposalForm";

export type GigStatus = keyof typeof gigStatuses;

export type ProposalViewPageProps = {
    proposal: ProposalDetails
    onApprove: () => Promise<GigApiFetcherResponse<void>>
    onReject: (value: RejectProposalDto) => Promise<GigApiFetcherResponse<void>>
    isApproving: boolean
    isRejecting: boolean
}

const ProposalViewPage = ({
    proposal,
    onApprove,
    onReject,
    isApproving,
    isRejecting
}: ProposalViewPageProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const { organizationConfig, gigDashboardDomain, talentTerminologyPlural, gigTerminology, talentTerminology } = useOrganizationConfig();
    const rejectionDialogBoxState = useDialogBoxState();

    const handleCloseButton = () => {
        navigate(-1);
    };

    const handleApprove = async () => {
        setSubmissionError(undefined);

        const response = await onApprove();

        if (!response.success) setSubmissionError(response.error);
    };

    const handleReject = async (value: RejectProposalDto) => {
        const response = await onReject(value);

        if (response.success) {
            handleCloseRejectFormDialog();
        }

        return response;
    };

    const handleCloseRejectFormDialog = () => {
        rejectionDialogBoxState.close();
    };

    if (!proposal) return <Loader />;

    const proposingTalentName = `${proposal.talentFirstName} ${proposal.talentLastName}`;
    const proposalStatus = proposalStatusLabels[proposal.proposalStatusId];
    const talentStringPlural = talentTerminology === "Talent" ? "talent" : talentTerminologyPlural.toLowerCase();

    return (
        <>
            <FormCard title={proposal.gig.title} onClose={handleCloseButton}>
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <CreatedByDisplay createdAt={proposal.createdAt} updatedAt={proposal.lastUpdatedAt} />
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology}:
                        <a
                            href={`https://${gigDashboardDomain}/find-${talentStringPlural}/${proposal.talentId}`}
                            target="_blank"
                            className="text-link underline"
                        >
                            <Typography sx={{ marginLeft: "8px", color: "blue" }} component="span">{proposingTalentName}</Typography>
                        </a>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {gigTerminology}: <Link className="ml-2 font-normal underline text-link" to={appPaths.gigs.view(proposal.gig.id)}>{proposal.gig.title}</Link>
                    </Typography>
                    {organizationConfig?.isPaymentsEnabled && (
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Milestones: <Link to={appPaths.proposals.milestones(proposal.id)} className="text-link underline ml-2 font-normal"> {proposal.milestones ? proposal.milestones.length : 0} </Link>
                        </Typography>
                    )}
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalStatus}</Typography>
                    </Typography>
                    {proposal.proposalStatusId === proposalStatuses.reviewRejected && (
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Rejected reason: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.rejectedReason}</Typography>
                        </Typography>
                    )}
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Days to complete: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.estimatedDurationDays}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Portfolio URL: <a className="ml-2 cursor-pointer underline font-normal" href={proposal.portfolioUrl} target="blank">{proposal.portfolioUrl}</a>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Intro: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.introduction}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Experience: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.relevantExperience}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        VAT Registered: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.isVATRegistered ? "Yes" : "No"}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        VAT Number: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.vatNumber}</Typography>
                    </Typography>
                    <FormErrors messages={submissionError?.userMessages} />
                    {proposal.proposalStatusId === proposalStatuses.pendingReview && (
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <LoadingButton
                                disabled={isRejecting || proposal.proposalStatusId !== 0}
                                loading={isRejecting}
                                color="secondary"
                                onClick={() => rejectionDialogBoxState.open()}
                            >
                                Reject
                            </LoadingButton>
                            <LoadingButton
                                disabled={isApproving || proposal.proposalStatusId !== 0}
                                loading={isApproving}
                                color="secondary"
                                onClick={handleApprove}
                            >
                                Approve
                            </LoadingButton>
                        </Box>
                    )}
                </Box>
            </FormCard>
            <DialogBox
                {...rejectionDialogBoxState}
                fullWidth
                maxWidth="sm"
                title="Reject proposal"
                contentText={`Please give a reason as to why this proposal is being rejected. This will be shown to the ${talentTerminology.toLowerCase()} who submitted the proposal.`}
            >
                <RejectProposalForm
                    handleCloseDialog={handleCloseRejectFormDialog}
                    onSubmit={handleReject}
                    loading={isRejecting}
                />
            </DialogBox>
        </>
    );
};

export default ProposalViewPage;