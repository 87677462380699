import { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Divider, Typography, Chip } from "@mui/material";
import { ClientInvitedUser, ResendInviteClientUserDto } from "../../api/models/client";
import formatDateTimeString from "../../utils/formatDateTimeString";
import { addDays, isBefore } from "date-fns";
import CopyIcon from "../CopyIcon";
import VisibleIcon from "../VisibleIcon";
import ResendInviteButton from "../ResendInviteButton";
import { GigApiFetcherResponse } from "../../api/common/fetching";

const isInviteExpired = (dateSent: string): boolean => {
    const givenDate = new Date(dateSent);
    const sevenDaysAgo = addDays(new Date(), -7);

    return isBefore(givenDate, sevenDaysAgo);
};

export type InvitedEmailDisplayProps = {
    invitedUsers: Array<ClientInvitedUser>
    handleResendInviteClientUser: (dto: ResendInviteClientUserDto) => Promise<GigApiFetcherResponse<void>>
}

const InvitedEmailDisplay = ({
    invitedUsers,
    handleResendInviteClientUser
}: InvitedEmailDisplayProps) => {
    const [showFullUrl, setShowFullUrl] = useState<{ [url: string]: boolean }>({});

    const toggleFullUrl = (url: string) => {
        setShowFullUrl(prev => ({
            ...prev,
            [url]: !prev[url]
        }));
    };

    return (
        <>
            {invitedUsers.map((invite) => {
                const isExpired = invite.dateSent ? isInviteExpired(invite.dateSent) : true;
                const isVisible = invite.inviteUrl && showFullUrl[invite.inviteUrl] || false;

                return (
                    <Box key={invite.email} sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                            <AccountCircleIcon fontSize="large" sx={{ marginRight: 1, color: "gray" }} />
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Typography component="span" sx={{ color: "gray" }}>
                                    Email: {invite.email}
                                </Typography>
                                {invite.dateSent && (
                                    <Typography component="span" sx={{ color: "gray", marginTop: "6px" }}>
                                        Sent: {invite.dateSent ? formatDateTimeString(invite.dateSent) : "N/A"}
                                    </Typography>
                                )}
                                {invite.inviteUrl && (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography component="span" sx={{ color: "gray" }}>
                                            Link:
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                                            <VisibleIcon
                                                title={isVisible ? "Hide full URL" : "View full URL"}
                                                onClick={() => toggleFullUrl(invite.inviteUrl || "")}
                                                isVisible={isVisible}
                                            />
                                            <CopyIcon
                                                title="Copy URL"
                                                textToCopy={invite.inviteUrl || ""}
                                                notificationText="URL copied to clipboard"
                                            />
                                        </Box>
                                    </Box>
                                )}
                                {isVisible && invite.inviteUrl && (
                                    <Typography component="span" sx={{ color: "gray", fontStyle: "italic", wordBreak: "break-all" }}>
                                        {invite.inviteUrl}
                                    </Typography>
                                )}
                                {isExpired && (
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}>
                                        <>
                                            <Typography component="span" sx={{ color: "red" }}>
                                                <Chip label="Expired" color="error" variant="outlined" />
                                            </Typography>
                                            <ResendInviteButton
                                                key={invite.email}
                                                email={invite.email}
                                                onResend={handleResendInviteClientUser}
                                            />
                                        </>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Divider />
                    </Box>
                );
            })}
        </>
    );
};

export default InvitedEmailDisplay;