export type EditableClientFields = {
    name: string,
    userEmails: string[],
}

export type CreateClientDto = EditableClientFields;

export type ClientSummary = {
    id: string,
    name: string,
    companyName?: string
    userEmail: string
    userFirstName: string
    userLastName: string
    isIR35CheckRequired: boolean
    isTurnoverChecked: boolean
    enablePurchaseOrders: boolean
    createdAt: string
    phoneNumber?: string
    badge: ClientBadge
    isWorksome: boolean
    signUpReasonId?: number
}

export type ClientMemberUser = {
    email: string,
    firstName: string, 
    lastName: string,
    userId: string,
}

export type ClientInvitedUser = {
    email: string
    inviteUrl?: string
    dateSent?: string
}

export type ClientDetails = ClientSummary & {
    memberUsers: Array<ClientMemberUser>,
    invitedUsers: Array<ClientInvitedUser>
    isTurnoverChecked: boolean
    isBalanceSheetChecked: boolean
    isEmployeesChecked: boolean
    aboutMe: string
    companyName?: string
    companyTagline?: string
    companyWebsite?: string
    companyNumber?: string
    hasAnyOngoingGigs: boolean
    location?: ClientLocationDto
}

export type UpdateClientDto = {
    name: string
    aboutMe: string
    companyName?: string
    companyTagline?: string
    companyWebsite?: string
    companyNumber?: string
    phoneNumber?: string
    isTurnoverChecked: boolean
    isBalanceSheetChecked: boolean
    isEmployeesChecked: boolean
    enablePurchaseOrders: boolean
    locationId?: string
    isWorksome: boolean
}

export type InviteClientUserDto = {
    emails: string[]
}

export type ResendInviteClientUserDto = {
    email: string
}

export type ClientBadge = {
    badgeId: string
    badgeName: string
    badgeDescription: string
    badgeImageFileName: string
    badgeType: string
    createdAt: string
}

export type AddClientBadgeDto = {
    badgeId: string
}

export type ClientLocationDto = {
    id: string
    name: string
}

export const giggedClientSignUpReasonOptions = [
    { label: "Hire now", value: 0 },
    { label: "Future hire", value: 1 },
    { label: "Browsing", value: 2 },
    { label: "None", value: 3 },
];