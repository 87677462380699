import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import gigApiFetcher, { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateSkillDto, SkillCount, SkillSummaryDto } from "./models/skills";

export const useCreateSkill = () => useAuthenticatedGigApiFetcher<CreateSkillDto, string, CreateSkillDto>("POST",
    (dto) => ({
        path: "api/skills",
        body: dto
    }));

export const fetchSkills = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<SkillSummaryDto[]> => {
    const path = `api/admin/skills?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<SkillSummaryDto>>({
        path: path,
        token: accessToken || undefined
    });

    return response.items;
};

export const fetchSkillOptions = async (query: string, accessToken: string | null) => {
    const skillsResponse = await fetchSkills(query, accessToken);

    return skillsResponse
        .map(skill => ({
            value: skill.id,
            label: skill.name,
        }));
};

export const fetchSkillsCount = async (accessToken: string | null) => {
    const response = await gigApiFetcher<never, SkillCount>({
        path: "api/admin/skills?pageIndex=0&pageSize=100",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response.totalCount;
};

export type UseSkillsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<SkillSummaryDto>>, "data"> & {
        skills: SkillSummaryDto[]
        sortOrder:  SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        createSkill: (dto: CreateSkillDto) => Promise<GigApiFetcherResponse<string>>
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        isCreatingSkill: boolean
    };

export const useSkills = (initialPageSize = 10): UseSkillsReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("Name");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const [createSkill, isCreatingSkill] = useCreateSkill();

    const url = "api/admin/skills?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<SkillSummaryDto>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        skills: apiCall.data?.items || [],
        sortOrder,
        orderBy,
        createSkill: async (dto: CreateSkillDto) => {
            const response = await createSkill(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        isCreatingSkill
    };
};