import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, Button, FormHelperText, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddIcon from "@mui/icons-material/Add";
import { ClientDetails } from "../../api/models/client";
import { useClient } from "../../api/client";
import ApiError from "../../api/common/apiError";
import MemberEmailDisplay from "./MemberEmailDisplay";
import InvitedEmailDisplay from "./InvitedEmailDisplay";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import ClientInviteUsersFormDialog from "./ClientInviteUsersFormDialog";
import { ClientInviteUserFormValues } from "./ClientInviteUsersFormValues";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { successToast, errorToast } from "../../toast";
import { ClientEditFormValues, useEditClientForm } from "./ClientEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import Checkbox from "../Checkbox";
import FormInputLabel from "../FormInputLabel";
import { useBadges } from "../../api/badges";
import { badgeTypes } from "../../api/models/badge";
import { ResendInviteClientUserDto } from "../../api/models/client";

export type ClientEditFormProps = {
    onSubmit: (values: ClientEditFormValues) => Promise<GigApiFetcherResponse<void>[]>
    initialValues: ClientDetails
    isSubmitting: boolean
}

const ClientEditForm = ({
    initialValues,
    onSubmit,
    isSubmitting,
}: ClientEditFormProps) => {
    const [clientInviteUsersDialogIsOpen, setClientInviteUsersDialogIsOpen] = useState(false);
    const appPaths = useAppPaths();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const {
        deleteUserFromClient,
        inviteClientUser,
        resendInviteClientUser,
        deleteClient,
        isInvitingClientUser,
        isDeletingUserFromClient,
        isDeletingClient,
        client
    } = useClient(initialValues.id);
    const methods = useEditClientForm({
        ...initialValues,
        badgeId: initialValues.badge?.badgeId || "",
        locationId: initialValues.location?.id || ""
    });
    const { control, formState: { isDirty } } = methods;
    const { organizationConfig, giggedClientTerminology } = useOrganizationConfig();
    const { data: badges } = useBadges({
        badgeTypeIds: [badgeTypes.client]
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
            badgeId: initialValues.badge?.badgeId,
            locationId: initialValues.location?.id || ""
        });
    }, [methods.reset, initialValues]);

    const handleFormSubmit = async (values: ClientEditFormValues) => {
        setSubmissionError(undefined);

        const responses = await onSubmit(values);

        if (responses.some(response => !response.success)) {
            responses.find(response => {
                !response.success ? setSubmissionError(response.error) : setSubmissionError(undefined);
            });
        }
    };

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.clients.index);
        }
    });

    const [openDeleteDialog, deleteDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDelete();
        }
    });

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.clients.index);
        }
    };

    const handleDelete = async () => {
        setSubmissionError(undefined);

        const response = await deleteClient(initialValues.id);

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.clients.index);
    };

    const handleInviteClientUser = async (values: ClientInviteUserFormValues) => {
        const response = await inviteClientUser(values);

        if (response.success) {
            successToast("Email invitations sent.");
            setClientInviteUsersDialogIsOpen(false);
        }

        return response;
    };

    const handleResendInviteClientUser = async (dto: ResendInviteClientUserDto) => {
        const response = await resendInviteClientUser(dto);

        if (response.success) {
            successToast("Invite resent successfully.");
        } else {
            errorToast("Failed to resend invite.");
        }
    
        return response;
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={
                            <FormTextInput
                                name="name"
                                disabled={isSubmitting}
                                variant="standard"
                                sx={{ backgroundColor: theme.palette.background.paper }}
                            />
                        }>
                        <Box sx={{
                            minHeight: "25rem",
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            }
                        }}>

                            <>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                                        Users
                                    </Typography>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => setClientInviteUsersDialogIsOpen(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        Invite new user
                                    </Button>

                                </Box>
                                {initialValues.memberUsers.map(memberUser => (
                                    <MemberEmailDisplay key={memberUser.userId} numberOfUsers={initialValues.memberUsers.length} user={memberUser} clientName={initialValues.name} deleteUser={deleteUserFromClient} isDeleting={isDeletingUserFromClient} />
                                ))}
                                {initialValues?.invitedUsers.length > 0 && (
                                    <>
                                        <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                                            Invites
                                        </Typography>
                                        <InvitedEmailDisplay invitedUsers={initialValues?.invitedUsers} handleResendInviteClientUser={handleResendInviteClientUser}/>
                                    </>
                                )}
                            </>

                            <Box className="space-y-2">
                                <FormInputLabel>Email</FormInputLabel>
                                <FormTextInput sx={{ pointerEvents: "none" }} name="" disabled placeholder={initialValues?.memberUsers[0] ? initialValues?.memberUsers[0].email : "Email not specified"} />
                            </Box>

                            <Box className="space-y-2">
                                <FormInputLabel required>Overview</FormInputLabel>
                                <FormTextInput required multiline name="aboutMe" />
                            </Box>

                            {organizationConfig?.isPaymentsEnabled && (
                                <>
                                    <Box className="space-y-2">
                                        <FormInputLabel>Phone number</FormInputLabel>
                                        <FormTextInput type="tel" name="phoneNumber" />
                                    </Box>
                                    <Box className="space-y-2">
                                        <FormInputLabel>Company name</FormInputLabel>
                                        <FormTextInput name="companyName" required />
                                    </Box>
                                    <Box className="space-y-2">
                                        <FormInputLabel>Company tagline</FormInputLabel>
                                        <FormTextInput name="companyTagline" />
                                    </Box>
                                    <Box className="space-y-2">
                                        <FormInputLabel>Company registration number (CRN)</FormInputLabel>
                                        <FormTextInput name="companyNumber" />
                                    </Box>
                                    <Box className="space-y-2">
                                        <FormInputLabel>Company website</FormInputLabel>
                                        <FormTextInput name="companyWebsite" />
                                    </Box>
                                    <Box>
                                        <FormInputLabel>IR35</FormInputLabel>
                                        <Controller
                                            name="isTurnoverChecked"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    label="Has an annual net turnover of more than £10.2 million"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="isBalanceSheetChecked"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    label="Has a balance sheet of more than £5.1 million"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="isEmployeesChecked"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    label="Has more than 50 employees"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        <FormInputLabel>Purchase orders</FormInputLabel>
                                        <Controller
                                            name="enablePurchaseOrders"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    label="Enable purchase orders"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </Box>
                                    {organizationConfig?.isWorksomeEnabled && (
                                        <Box>
                                            {/* <FormInputLabel>Worksome</FormInputLabel> */}
                                            <img
                                                className="block max-h-[15px] w-auto mr-auto" 
                                                src="/worksome-logo-black-full-150x24.svg"
                                                alt="Worksome logo" 
                                            />
                                            <Controller
                                                name="isWorksome"
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Checkbox
                                                        label="Enable as Worksome representative"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    )}                                    
                                </>
                            )}
                            {organizationConfig?.isBadgesEnabled && (
                                <Box className="space-y-2 flex flex-col !mb-8">
                                    <FormInputLabel>{organizationConfig?.name === "Gigged.AI" ? "Verification status" : "Badge"}</FormInputLabel>
                                    <Controller
                                        name="badgeId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                color="secondary"
                                                disabled={isSubmitting}
                                                displayEmpty
                                                error={!!methods.formState.errors.badgeId}
                                            >
                                                {
                                                    badges.length > 0 && badges.map(option => (
                                                        <MenuItem key={option?.badgeId} value={option?.badgeId}>{option?.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                    />
                                    {methods.formState.errors.badgeId && <FormHelperText className="!ml-4" error={!!methods.formState.errors.badgeId}>{methods.formState.errors.badgeId.message}</FormHelperText>}
                                </Box>
                            )}

                            <FormErrors messages={submissionError?.userMessages} />
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end", marginTop: "auto", gap: 2 } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "auto" }
                            }>
                                <LoadingButton
                                    color="secondary"
                                    startIcon={<DeleteForeverOutlinedIcon sx={isSubmitting || isDeletingClient ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting || isDeletingClient}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                    onClick={openDeleteDialog}
                                >
                                    Delete
                                </LoadingButton>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting || isDeletingClient ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting || isDeletingClient}
                                    disabled={!isDirty || isDeletingClient}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            {clientInviteUsersDialogIsOpen && (
                <ClientInviteUsersFormDialog
                    isOpen={true}
                    onClose={() => setClientInviteUsersDialogIsOpen(false)}
                    handleInviteClientUser={handleInviteClientUser}
                    isInviting={isInvitingClientUser}
                />
            )}
            <ConfirmDialogBox
                {...deleteDialogState}
                message={`Are you sure you want to delete this ${giggedClientTerminology.toLowerCase()}? This action cannot be undone.`}
                warningMessage={client?.hasAnyOngoingGigs ? `This ${giggedClientTerminology.toLowerCase()} has one or more ongoing gigs. Please make sure to consider any outstanding payments before deleting the ${giggedClientTerminology.toLowerCase()}.` : undefined}
                confirmButtonText="Delete"
            />
        </>
    );
};

export default ClientEditForm;