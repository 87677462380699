import { FieldError } from "react-hook-form";

import SkillsSearchDropdown from "./SkillsSearchDropdown";
import { FormSearchDropdownOption } from "./FormSearchDropdown/FormSearchDropdown";
import FormInputLabel from "./FormInputLabel";
import { Chip } from "@mui/material";

export type GigSkillsInputProps = {
    addSkill: (skill: { id: string, name: string }) => void
    removeSkill: (index: number) => void
    skills: { id: string, name: string }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any
    showError?: boolean
    loading?: boolean
    required?: boolean
}

const GigSkillsInput = ({
    addSkill,
    removeSkill,
    skills,
    error,
    showError,
    loading,
    required
}: GigSkillsInputProps) => {
    const handleSelectSkill = (_: string, option: FormSearchDropdownOption | null) => {
        if (option === null) return;
        addSkill({
            id: option.value,
            name: option.label
        });
    };

    const handleRemoveSkill = (index: number) => {
        removeSkill(index);
    };

    const fieldError: FieldError | undefined = (error)?.message ? error as FieldError : undefined;

    return (
        <div className="space-y-2">
            <FormInputLabel required={required}>Top 3 skills</FormInputLabel>
            {skills.length < 3 &&
                <SkillsSearchDropdown
                    value={""}
                    onChange={handleSelectSkill}
                    disabled={skills.length === 3}
                    disabledSkillIds={skills.map(_ => _.id)}
                    error={showError ? fieldError : undefined}
                />
            }
            <div className="-mt-2 -mr-2 child:mr-2 child:mt-2 pt-2">
                {skills.map((skill, index) => (
                    <Chip
                        key={skill.id}
                        label={skill.name}
                        onDelete={() => handleRemoveSkill(index)}
                        sx={{ marginRight: 1, marginBottom: 1 }}
                        disabled={loading}
                        variant="outlined"
                        color="secondary"
                    />
                ))}
            </div>
        </div>
    );
};

export default GigSkillsInput;