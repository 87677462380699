export type EditableOrganizationConfigFields = {
    termsAndConditionsUrl: string
    isEmailDomainWhitelisted: boolean
    whitelistedEmailDomains?: string[]
    gigTerminology: string
    giggedClientTerminology: string
    talentTerminology: string
    isTalentProfilesPrivate: boolean
    isProposalReviewsEnabled: boolean
    isDisputesEnabled: boolean
    logoUrl?: string
    theme: string
}

export type OrganizationConfigDto = EditableOrganizationConfigFields & {
    id: string
    isPublicGiggedClientSignUpEnabled: boolean
    isPaymentsEnabled: boolean
    isReportsEnabled: boolean
    hidePoweredByGiggedAi: boolean
    gigDashboardDomain: string
    isAllowGiggedClientUserToInviteEnabled: boolean
    hideGiggedClientCompanyInfo: boolean
    isTalentAvailabilityEnabled: boolean
    isTalentAgencyEnabled: boolean
    isBadgesEnabled: boolean
    name: string
    isWorksomeEnabled: boolean
}

export type AddLogoToOrganizationDto = {
    logoDataUri: string
}

export type UpdateOrganizationConfigDto = Omit<OrganizationConfigDto, "id" | "isPaymentsEnabled" | "hidePoweredByGiggedAi" | "isReportsEnabled">;

export const organizationThemeOptions = [
    { label: "Default", value: "default" },
    { label: "Red", value: "red" },
    { label: "Blue", value: "blue" },
    { label: "Purple", value: "purple" },
    { label: "Black", value: "black" }
];