import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type ProposalMilestoneEditFormValues = {
    description: string
    amount: number 
    discountAmount: number 
    clientPaymentCollectedDate: Date | null
    clientPaymentPayPalId?: string
    clientPaymentStripePaymentIntentId?: string
    clientPaymentStripeChargeId?: string
    talentStripeTransferId?: string
    dateCompleted: Date | null
    stripeInvoicePaidDate: Date | null
}

const editProposalMilestoneSchema = () => {

    return yup
        .object({
            description: yup.string().required("Description is required."),
            amount: yup.number().min(1, "Minimum amount is 1.").typeError("Please enter a number."),
            discountAmount: yup.number()
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
            clientPaymentCollectedDate: yup.date()
                .nullable()
                .optional()
                .typeError("Must be a valid date."),
            clientPaymentPaypalId: yup.string()
                .nullable()
                .optional(),
            clientPaymentStripePaymentIntentId: yup.string()
                .nullable()
                .optional(),
            clientPaymentStripeChargeId: yup.string()
                .nullable()
                .optional(),
            talentStripeTransferId: yup.string()
                .nullable()
                .optional(),
            dateCompleted: yup.date()
                .nullable()
                .optional()
                .typeError("Must be a valid date."),
            invoicePaidDate: yup.date()
                .nullable()
                .optional()
                .typeError("Must be a valid date."),
        });
};

export const useEditProposalMilestoneForm = (initialValues?: ProposalMilestoneEditFormValues) => {
    const schema = editProposalMilestoneSchema();

    return useForm<ProposalMilestoneEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onBlur", 
        defaultValues: {
            description: initialValues?.description ?? "",
            amount: initialValues?.amount ?? 0,
            discountAmount: initialValues?.discountAmount ?? 0,
            clientPaymentCollectedDate: initialValues?.clientPaymentCollectedDate ?? null,
            clientPaymentPayPalId: initialValues?.clientPaymentPayPalId ?? "",
            clientPaymentStripePaymentIntentId: initialValues?.clientPaymentStripePaymentIntentId ?? "",
            clientPaymentStripeChargeId: initialValues?.clientPaymentStripeChargeId ?? "",
            talentStripeTransferId: initialValues?.talentStripeTransferId ?? "",
            dateCompleted: initialValues?.dateCompleted ?? null,
            stripeInvoicePaidDate: initialValues?.stripeInvoicePaidDate ?? null
        }
    });
};