import { toast } from "react-toastify";

export const successToast = ( message:string ) => {
    toast.success(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
    );
};

export const errorToast = ( message: string ) => {
    toast.error(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
    );
};