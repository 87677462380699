import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProposalMilestoneDetailsDto } from "../../api/models/proposalMilestone";
import { useOrganizationConfig } from "../../api/organization";
import { useAppPaths } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import CreatedByDisplay from "../CreatedByDisplay";
import EditButton from "../EditButton";
import FormCard from "../FormCard";
import MoneyDisplay from "../MoneyDisplay";
import config from "../../config.json";

export type ProposalMilestoneViewPageProps = {
    proposalMilestone: ProposalMilestoneDetailsDto
}
const ProposalMilestoneViewPage = ({
    proposalMilestone
}: ProposalMilestoneViewPageProps) => {
    const { gigTerminology, talentTerminology, giggedClientTerminology } = useOrganizationConfig();
    const theme = useTheme();
    const navigate = useNavigate();
    const appPaths = useAppPaths();

    const handleEditMilestone = () => {
        navigate(appPaths.milestones.edit(proposalMilestone.id));
    };

    const handleCloseButton = () => {
        navigate(appPaths.proposals.milestones(proposalMilestone.proposalId));
    };

    return (
        <>
            <FormCard
                title={`${gigTerminology} milestone`}
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <CreatedByDisplay createdBy={proposalMilestone.talentName} createdAt={proposalMilestone.createdAt} updatedAt={proposalMilestone.lastUpdatedAt} />

                    <Divider />

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Milestone ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Milestone description: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.description}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Milestone amount: 
                        <Typography sx={{ marginLeft: "8px" }} component="span"> 
                            {proposalMilestone.isVatRegistered ? (
                                <>
                                    <MoneyDisplay value={proposalMilestone.amount * config.VatMultiplier} /><span> (incl. 20% VAT)</span>
                                </>
                            ) : (
                                <MoneyDisplay value={proposalMilestone.amount || 0} /> 
                            )}
                        </Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Milestone discount amount: <Typography sx={{ marginLeft: "8px" }} component="span"> <MoneyDisplay value={proposalMilestone.discountAmount || 0} /> </Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Date milestone completed: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.dateCompleted ? formatDateOnlyString(proposalMilestone.dateCompleted.toISOString()) : "-"}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {gigTerminology} ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.gigId}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {gigTerminology} title: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.gigTitle}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Proposal ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.proposalId}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology} name: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.talentName}</Typography>
                    </Typography>

                    {proposalMilestone.invoiceId && (
                        <>
                            <Divider />

                            <Typography variant="h6">Payment details</Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Milestone invoice ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.invoiceId || "-"}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                {giggedClientTerminology} payment collected date: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.clientPaymentCollectedDate ? formatDateOnlyString(proposalMilestone.clientPaymentCollectedDate.toISOString()) : "-"}</Typography>
                            </Typography>
                            {!proposalMilestone.stripeInvoiceId && (
                                <>
                                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                        {giggedClientTerminology} Stripe payment intent ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.clientPaymentStripePaymentIntentId || "-"}</Typography>
                                    </Typography>
                                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                        {giggedClientTerminology} Stripe charge ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.clientPaymentStripeChargeId || "-"}</Typography>
                                    </Typography>
                                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                        {giggedClientTerminology} PayPal ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.clientPaymentPayPalId || "-"}</Typography>
                                    </Typography>
                                </>
                            )}
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                {talentTerminology} Stripe transfer ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.talentStripeTransferId || "-"}</Typography>
                            </Typography>
                        </>
                    )}

                    {proposalMilestone.stripeInvoiceId && (
                        <>
                            <Divider />
                            <Typography variant="h6">Stripe invoice (PO payment only)</Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                PO Stripe invoice ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.stripeInvoiceId || "-"}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                PO Stripe invoice paid date: <Typography sx={{ marginLeft: "8px" }} component="span">{formatDateOnlyString(proposalMilestone.stripeInvoicePaidDate?.toISOString()) || "-"}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                PO email: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.stripeInvoiceEmail || "-"}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                PO number: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.purchaseOrderNumber || "-"}</Typography>
                            </Typography>
                        </>
                    )}
                    <Box className="flex justify-end items-center">
                        <EditButton
                            buttonText={"Edit milestone"}
                            onClick={handleEditMilestone}
                            disabled={false}
                        />
                    </Box>
                </Box>
            </FormCard>
        </>
    );
};

export default ProposalMilestoneViewPage;