import { useTalentBankDetails } from "../api/talentBankAccount";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import TalentBankDetailsView from "../components/TalentBankDetailsView";
import { useTalentId } from "../Routes";

const TalentBankDetailsPage = () => {
    const talentId = useTalentId();
    const { talentBankDetails, isLoading} = useTalentBankDetails(talentId);

    if (isLoading || !talentBankDetails) {
        return <Loader />;
    }

    return (
        <FormContainer>
            <TalentBankDetailsView bankDetails={talentBankDetails} />
        </FormContainer>
    );
};

export default TalentBankDetailsPage;
