import { Box, Chip, Typography, useTheme } from "@mui/material";
import { gigStatuses, GigSummary } from "../../api/models/gig";

import CreatedByDisplay from "../../components/CreatedByDisplay";
import FormCard from "../../components/FormCard";
import NameDisplayLink from "../../components/NameDisplayLink";
import StatusDisplay from "../../components/StatusDisplay";
import { useAppPaths } from "../../Routes";
import capitalizeString from "../../utils/capitalizeString";
import AssignedTalentDisplay from "./AssignedTalentDisplay";
import { GigStatus } from "../../pages/GigPage";
import ProposalCountDisplay from "./ProposalCountDisplay";
import EditButton from "../EditButton";
import Loader from "../../components/Loader";
import { successToast } from "../../toast";
import { useOrganizationConfig } from "../../api/organization";
import { useGigs } from "../../api/gigs";
import useConfirmDialogBoxState from "../../components/ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../../components/ConfirmDialogBox";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import pluralizeString from "../../utils/pluralizeString";
import BudgetDisplay from "./BudgetDisplay";
import { useGig } from "../../api/gig";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import formatDateString from "../../utils/formatDateString";
import GigMatchesDisplay from "./GigMatchesDisplay";
import DailyRateDisplay from "./DailyRateDisplay";

export type GigViewPageProps = {
    gig: GigSummary
    loading: boolean
    handleCloseButton: () => void
    handleEditGig: () => void
}

const GigViewPage = ({
    gig,
    loading,
    handleCloseButton,
    handleEditGig
}: GigViewPageProps) => {
    const theme = useTheme();
    const appPaths = useAppPaths();
    const { deleteGig, isDeletingGig, isLoading } = useGigs();
    const { putGigOnHold, isPuttingGigOnHold } = useGig(gig.id);
    const { gigTerminology, organizationConfig } = useOrganizationConfig();
    const [openConfirmDeleteDialog, confirmDeleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => handleDeleteGig()
    });
    const [openHoldGigDialogState, confirmHoldGigDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => handlePutGigOnHold()
    });
    const navigate = useNavigate();
    const isLargeScreen = useIsLargeScreen();

    const gigStatus = Object.keys(gigStatuses).find(key => gigStatuses[key as GigStatus] === gig?.gigStatusId);
    const capitalizedGigStatus = gigStatus ? capitalizeString(gigStatus) : "";
    const assignedTalentName = gig?.hiredProposal ? `${gig?.hiredProposal.talentFirstName} ${gig.hiredProposal.talentLastName}` : "Not yet assigned";
    const gigIsEditable = gigStatus === "draft" || gigStatus === "posted";


    const handleDeleteGig = async () => {
        const response = await deleteGig(gig.id);

        if (response.success) {
            successToast(`${gigTerminology} deleted successfully.`);
            navigate(appPaths.gigs.index);
        }

        return response;
    };

    const handlePutGigOnHold = async () => {
        const response = await putGigOnHold();

        if (response.success) {
            successToast(`${gigTerminology} put on hold successfully.`);
            return;
        }
    };

    if (!gig || loading) return <Loader />;

    return (
        <>
            <FormCard
                title={gig?.title}
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.id}</Typography>
                    </Typography>
                    <NameDisplayLink
                        href={appPaths.clients.edit(gig.giggedClientId)}
                        name={gig.giggedClientName}
                    />
                    <CreatedByDisplay
                        createdBy={gig.createdByUserName}
                        createdAt={gig.createdAt}
                        updatedBy={gig.lastUpdatedByUserName}
                        updatedAt={gig.lastUpdatedAt}
                    />
                    <StatusDisplay status={capitalizedGigStatus} />
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Description: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.description}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Start date: <Typography sx={{ marginLeft: "8px" }} component="span">{formatDateString(gig.startDate.toISOString())}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Deadline date: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.deadlineDate ? formatDateString(gig.deadlineDate.toISOString()) :  "No deadline"}</Typography>
                    </Typography>
                    {gig.isTimeAndMaterial && 
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Number of days: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.numberOfDays}</Typography>
                        </Typography>
                    }
                    {organizationConfig?.isPaymentsEnabled && (
                        gig.isTimeAndMaterial ? (
                            <>
                                <DailyRateDisplay dailyRate={gig?.dailyRate || 0} />
                                <BudgetDisplay budget={gig?.budget || 0} />
                            </>
                        ) : (
                            <BudgetDisplay budget={gig?.budget || 0} />
                        )
                    )}
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Duration: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.expectedDurationLabel}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Stage: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.initialStageLabel}</Typography>
                    </Typography>                    
                    <Typography fontWeight="bold" component="label">
                        Skills:
                    </Typography>
                    <div className="-mt-2 -mr-2 child:mr-2 child:mt-2 pt-2">
                        {gig.skills.map((skill) => (
                            <Chip
                                key={skill.id}
                                label={skill.name}
                                disabled={loading}
                                variant="outlined"
                                color="secondary"
                            />
                        ))}
                    </div>
                    <ProposalCountDisplay proposalsCount={gig?.proposalsCount} />
                    { gig.matches.length > 0 && <GigMatchesDisplay matches={gig.matches} /> }
                    <AssignedTalentDisplay talentName={assignedTalentName} />
                    <Box sx={
                        isLargeScreen ?
                            { display: "flex", marginTop: "auto", gap: 2, flexDirection: "row-reverse" } :
                            { display: "flex", flexDirection: "column", justifyContent: "flex-end", marginTop: "auto", gap: 1 }
                    }>
                        <EditButton
                            disabled={!gigIsEditable}
                            buttonText={`Edit ${gigTerminology.toLowerCase()}`}
                            disabledTooltip={`Only draft and posted ${pluralizeString(gigTerminology.toLowerCase())} can be edited.`}
                            onClick={handleEditGig}
                        />
                        {gig.gigStatusId === gigStatuses.posted && <LoadingButton type="button" onClick={openHoldGigDialogState} className="w-full md:w-auto md:mr-4" loading={isPuttingGigOnHold} disabled={isPuttingGigOnHold}>Put on hold</LoadingButton>}
                        <LoadingButton sx={isLargeScreen ? { marginRight: "auto" } : { marginRight: "unset" }} loading={isDeletingGig || isLoading} onClick={openConfirmDeleteDialog}>{`Delete ${gigTerminology.toLowerCase()}`}</LoadingButton>
                    </Box>
                </Box>
            </FormCard>
            <ConfirmDialogBox
                {...confirmDeleteDialogProps}
                message={`Are you sure you want to delete this ${gigTerminology.toLowerCase()}?`}
                confirmButtonText="Delete"
                isProcessingConfirm={isDeletingGig || isLoading}
            />
            <ConfirmDialogBox
                {...confirmHoldGigDialogProps}
                message={`Putting this ${gigTerminology.toLowerCase()} on hold will change its status to 'Draft'. Continue?`}
                confirmButtonText="Put on hold"
                cancelButtonText="Back"
                isProcessingConfirm={isPuttingGigOnHold || isLoading}
            />
        </>
    );
};

export default GigViewPage;