import { useProposal } from "../api/proposal";
import FormContainer from "../components/FormContainer";
import { useProposalId } from "../Routes";
import ProposalViewPage from "../components/ProposalView/ProposalViewPage";
import { successToast } from "../toast";
import { RejectProposalDto } from "../api/models/proposal";


const ProposalPage = () => {
    const proposalId = useProposalId();
    const { proposal, approveProposal, rejectProposal, isApprovingProposal, isRejectingProposal } = useProposal(proposalId);

    const handleApprove = async () => {
        const response = await approveProposal();

        if (response.success) successToast("Proposal approved.");

        return response;
    };
    
    const handleReject = async (value: RejectProposalDto) => {
        const response = await rejectProposal(value);

        if (response.success) successToast("Proposal rejected.");

        return response;
    };
    
    return (
        <FormContainer>
            <ProposalViewPage
                proposal={proposal}
                onApprove={handleApprove}
                onReject={handleReject}
                isApproving={isApprovingProposal}
                isRejecting={isRejectingProposal}
            />
        </FormContainer>
    );
};

export default ProposalPage;