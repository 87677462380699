import { ApiProposalMilestoneSummaryDto } from "./api/proposalMilestone";

export type ProposalSummaryDto = {
    id: string
    estimatedDurationDays: number
    introduction: string
    relevantExperience: string
    portfolioUrl?: string
    proposalStatusId: number
    gig: ApiProposalGigSummary
    gigId: string
    gigTitle: string
    talentId: string
    talentFirstName: string
    talentLastName: string
    createdAt: string
    lastUpdatedAt?: string
    milestones: ApiProposalMilestoneSummaryDto[]
    isVATRegistered: boolean
    vatNumber?: string
    isWorksome: boolean
    isTimeAndMaterial: boolean
    dailyRate: number | null
    rejectedReason?: string
}

export type ProposalDetails = {
    id: string
    estimatedDurationDays: number
    introduction: string
    relevantExperience: string
    portfolioUrl?: string
    gig: ApiProposalGigSummary
    talentId: string
    talentFirstName: string
    talentLastName: string
    createdAt: string
    lastUpdatedAt?: string
    proposalStatusId: number
    milestones: ApiProposalMilestoneSummaryDto[]
    isVATRegistered: boolean
    vatNumber?: string
    isWorksome: boolean
    isTimeAndMaterial: boolean
    dailyRate: number | null
    rejectedReason?: string
}

export type ApiProposalGigSummary = {
    id: string,
    title: string,
    expectedDurationId: number
    startDate: string
    deadlineDate?: string
    description: string
    initialStageId: number
    gigStatusId: number
    giggedClientId: string
    giggedClientName: string
    isTimeAndMaterial: boolean
    dailyRate: number | null
    numberOfDays: number | null
}

export type RejectProposalDto = {
    rejectedReason: string
}

export const proposalStatusLabels = [
    "Pending Review", 
    "Review Rejected", 
    "Submitted", 
    "Hired", 
    "Unsuccessful",
    "Cancelled",
    "Completed"
];

export const proposalStatuses = {
    pendingReview: 0,
    reviewRejected: 1,
    submitted: 2,
    hired: 3,
    unsuccessful: 4,
    cancelled: 5,
    completed: 6,
};