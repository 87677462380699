import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ProposalDetails, RejectProposalDto } from "./models/proposal";

export const useApproveProposal = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (proposalId) => ({
        path: `api/proposals/${proposalId}/actions/approve`,
    }));

export const useRejectProposal = () => useAuthenticatedGigApiFetcher<RejectProposalDto, void, { proposalId: string, dto: RejectProposalDto}>("PUT",
    ({ proposalId, dto }) => ({
        path: `api/proposals/${proposalId}/actions/reject`,
        body: dto
    }));
    
export type UseProposalReturn<TProposalId extends string | undefined> = TProposalId extends undefined ?
    { proposal: undefined } :
    {
        proposal: ProposalDetails
        approveProposal: () => Promise<GigApiFetcherResponse<void>>
        rejectProposal: (value: RejectProposalDto) => Promise<GigApiFetcherResponse<void>>
        isApprovingProposal: boolean
        isRejectingProposal: boolean
    }

export const useProposal = <TProposalId extends string | undefined>(proposalId: TProposalId) => {
    const url = `api/proposals/${proposalId}`;
    const apiCall = useGiggedApiSWR<ProposalDetails>(url);
    
    const [approveProposal, isApprovingProposal] = useApproveProposal();
    const [rejectProposal, isRejectingProposal] = useRejectProposal();

    if (proposalId === undefined) return { proposal: undefined } as UseProposalReturn<TProposalId>;

    return {
        ...apiCall,
        proposal: apiCall.data,
        approveProposal: async () => {
            const response = await approveProposal(proposalId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        }, 
        rejectProposal: async (dto: RejectProposalDto) => {
            const response = await rejectProposal({ proposalId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        }, 
        isApprovingProposal,
        isRejectingProposal
    } as UseProposalReturn<TProposalId>;
};