import React, { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import gigApiFetcher, { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateClientDto, ClientSummary } from "./models/client";

export const useCreateClient = () => useAuthenticatedGigApiFetcher<CreateClientDto, string, CreateClientDto>("POST",
    (dto) => ({
        path: "api/gigged-clients/",
        body: dto
    }));

export const fetchClients = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<ClientSummary[]> => {
    const path = `api/gigged-clients?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<ClientSummary>>({
        path: path,
        token: accessToken || undefined
    });

    return response.items;
};

export type UseClientsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ClientSummary>>, "data"> & {
        clients: ClientSummary[]
        orderBy: string
        sortOrder:  SortOrder
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSearchTerm: (searchTerm?: string) => void
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setIr35Filter: (ir35Filter?: boolean) => void
        setPurchaseOrderFilter: (purchaseOrderFilter?: boolean) => void
        setIsTurnoverChecked: (isTurnoverCheckedFilter?: boolean) => void
        setBadgeIds: (badgeIds: string[]) => void
        createClient: (dto: CreateClientDto) => Promise<GigApiFetcherResponse<string>>
        isCreatingClient: boolean
    };

export const useClients = (initialPageSize = 10): UseClientsReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("CreatedAt");
    const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [ir35Filter, setIr35Filter] = useState<boolean | undefined>();
    const [purchaseOrderFilter, setPurchaseOrderFilter] = useState<boolean | undefined>();
    const [isTurnoverChecked, setIsTurnoverChecked] = useState<boolean | undefined>();
    const [badgeIds, setBadgeIds] = useState<string[]>([]);

    const url = "api/gigged-clients?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        (ir35Filter === undefined ? "" : `isIR35CheckRequired=${ir35Filter}&`) +
        (purchaseOrderFilter === undefined ? "" : `isPurchaseOrderEnabled=${purchaseOrderFilter}&`) +
        (isTurnoverChecked === undefined ? "" : `isTurnoverChecked=${isTurnoverChecked}`) +
        (badgeIds.length > 0 ? `&badgeIds=${badgeIds.join(",")}&` : "");


    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ClientSummary>>(url);

    const [createClient, isCreatingClient] = useCreateClient();

    return {
        ...apiCall,
        ...apiCall.data,
        clients: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        setIr35Filter,
        setPurchaseOrderFilter,
        setIsTurnoverChecked,
        setBadgeIds,
        createClient: async (dto: CreateClientDto) => {
            const response = await createClient(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingClient,
    };
};