import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { Box, Button, Fab, Divider, ListItemText, MenuItem, MenuList, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButtonMenu, MenuCheckboxGroup } from "../Menu";
import SearchBox from "../SearchBox";
import GigsTable from "./GigsTable";
import DeletableChips from "../DeletableChips";
import { gigStatuses } from "../../api/models/gig";
import { useOrganizationConfig } from "../../api/organization";
import pluralizeString from "../../utils/pluralizeString";
import { Link } from "react-router-dom";
import { useAppPaths } from "../../Routes";

const GigsTableWithSearch = () => {
    const theme = useTheme();
    const appPaths = useAppPaths();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const [filterByGigStatusIds, setFilterByGigStatusIds] = useState<number[]>(() => {
        const cachedFilterByGigStatusIds = sessionStorage.getItem("filterByGigStatusIds");
        return cachedFilterByGigStatusIds ? JSON.parse(cachedFilterByGigStatusIds) : [];
    });
    const [searchTerm, setSearchTerm] = useState(() => {
        return sessionStorage.getItem("searchTerm") || "";
    });
    const [filterByComplianceCheckRequired, setFilterByComplianceCheckRequired] = useState(() => {
        const cachedFilterByComplianceCheckRequired = sessionStorage.getItem("filterByComplianceCheckRequired");
        return cachedFilterByComplianceCheckRequired ? JSON.parse(cachedFilterByComplianceCheckRequired) : false;
    });
    const [filterByComplianceCheckNotRequired, setFilterByComplianceCheckNotRequired] = useState(() => {
        const cachedFilterByComplianceCheckNotRequired = sessionStorage.getItem("filterByComplianceCheckNotRequired");
        return cachedFilterByComplianceCheckNotRequired ? JSON.parse(cachedFilterByComplianceCheckNotRequired) : false;
    });
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { gigTerminology } = useOrganizationConfig();

    useEffect(() => {
        sessionStorage.setItem("filterByGigStatusIds", JSON.stringify(filterByGigStatusIds));
    }, [filterByGigStatusIds]);

    useEffect(() => {
        sessionStorage.setItem("searchTerm", searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        sessionStorage.setItem("filterByComplianceCheckRequired", JSON.stringify(filterByComplianceCheckRequired));
    }, [filterByComplianceCheckRequired]);

    useEffect(() => {
        sessionStorage.setItem("filterByComplianceCheckNotRequired", JSON.stringify(filterByComplianceCheckNotRequired));
    }, [filterByComplianceCheckNotRequired]);

    const removeFilterByGigStatusId = (gigStatusId: number) => {
        setFilterByGigStatusIds(filterByGigStatusIds.filter(id => id !== gigStatusId));
    };

    const addFilterByGigStatusId = (gigStatusId: number) => {
        const index = filterByGigStatusIds.indexOf(gigStatusId);

        if (index !== -1) {
            console.warn("Tried to add gig status filter but gig status was already being filtered", gigStatusId);
            return;
        }

        setFilterByGigStatusIds([...filterByGigStatusIds, gigStatusId]);
    };

    const isGigStatusIdFiltered = (gigStatusId: number): boolean => {
        return filterByGigStatusIds.find(id => id === gigStatusId) !== undefined;
    };
        
    const complianceCheckFilter = filterByComplianceCheckRequired === filterByComplianceCheckNotRequired ? undefined : filterByComplianceCheckRequired;

    const filterChips = (
        <DeletableChips
            chips={[
                {
                    label: "Draft",
                    onDelete: () => removeFilterByGigStatusId(gigStatuses.draft),
                    visible: isGigStatusIdFiltered(gigStatuses.draft),
                },
                {
                    label: "Posted",
                    onDelete: () => removeFilterByGigStatusId(gigStatuses.posted),
                    visible: isGigStatusIdFiltered(gigStatuses.posted)
                },
                {
                    label: "Hired",
                    onDelete: () => removeFilterByGigStatusId(gigStatuses.hired),
                    visible: isGigStatusIdFiltered(gigStatuses.hired),
                },
                {
                    label: "Cancelled",
                    onDelete: () => removeFilterByGigStatusId(gigStatuses.cancelled),
                    visible: isGigStatusIdFiltered(gigStatuses.cancelled),
                },
                {
                    label: "Completed",
                    onDelete: () => removeFilterByGigStatusId(gigStatuses.completed),
                    visible: isGigStatusIdFiltered(gigStatuses.completed),
                },
                {
                    label: "Compliance check required",
                    onDelete: () => setFilterByComplianceCheckRequired(false),
                    visible: filterByComplianceCheckRequired,
                },
                {
                    label: "Compliance check not required",
                    onDelete: () => setFilterByComplianceCheckNotRequired(false),
                    visible: filterByComplianceCheckNotRequired,
                },
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? "Search by Title" : `Search ${pluralizeString(gigTerminology)}`}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={showFilterChipsInline ? { minWidth: "28rem", background: "#FFFFFF" } :  { flexGrow: 1, background: "#FFFFFF" }}
                />
                {showFilterChipsInline && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
                <IconButtonMenu
                    id="filter-gigs"
                    icon={isLargeScreen ? <FilterIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" /> : <MoreVertIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />}
                >
                    <MenuList sx={{ width: "17rem", padding: 0}}>
                        <MenuCheckboxGroup
                            label="Status"
                            options={[
                                {
                                    label: "Draft",
                                    value: isGigStatusIdFiltered(gigStatuses.draft),
                                    onChange: () => isGigStatusIdFiltered(gigStatuses.draft)
                                        ? removeFilterByGigStatusId(gigStatuses.draft)
                                        : addFilterByGigStatusId(gigStatuses.draft)
                                },
                                {
                                    label: "Posted",
                                    value: isGigStatusIdFiltered(gigStatuses.posted),
                                    onChange: () => isGigStatusIdFiltered(gigStatuses.posted)
                                        ? removeFilterByGigStatusId(gigStatuses.posted)
                                        : addFilterByGigStatusId(gigStatuses.posted)
                                },
                                {
                                    label: "Hired",
                                    value: isGigStatusIdFiltered(gigStatuses.hired),
                                    onChange: () => isGigStatusIdFiltered(gigStatuses.hired)
                                        ? removeFilterByGigStatusId(gigStatuses.hired)
                                        : addFilterByGigStatusId(gigStatuses.hired)
                                },
                                {
                                    label: "Cancelled",
                                    value: isGigStatusIdFiltered(gigStatuses.cancelled),
                                    onChange: () => isGigStatusIdFiltered(gigStatuses.cancelled)
                                        ? removeFilterByGigStatusId(gigStatuses.cancelled)
                                        : addFilterByGigStatusId(gigStatuses.cancelled)
                                },
                                {
                                    label: "Completed",
                                    value: isGigStatusIdFiltered(gigStatuses.completed),
                                    onChange: () => isGigStatusIdFiltered(gigStatuses.completed)
                                        ? removeFilterByGigStatusId(gigStatuses.completed)
                                        : addFilterByGigStatusId(gigStatuses.completed)
                                },
                            ]}
                        />
                        <MenuCheckboxGroup
                            label="Compliance check required"
                            options={[
                                {
                                    label: "Yes",
                                    value: filterByComplianceCheckRequired,
                                    onChange: setFilterByComplianceCheckRequired
                                },
                                {
                                    label: "No",
                                    value: filterByComplianceCheckNotRequired,
                                    onChange: setFilterByComplianceCheckNotRequired
                                },
                            ]}
                        />
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setFilterByGigStatusIds([]);
                                setFilterByComplianceCheckRequired(false);
                                setFilterByComplianceCheckNotRequired(false);   
                            }}
                        >
                            <ListItemText sx={{ textAlign: "right" }}>
                                Clear filters
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </IconButtonMenu>

                {isLargeScreen && (
                    <Link to={appPaths.gigs.create} style={{ textDecoration: "none", outline: "none", marginLeft: "1.25rem" }}>
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            Create
                        </Button>
                    </Link>
                )}
            </Box>
            {!showFilterChipsInline && (
                <Box sx={{
                    marginTop: "1.5rem"
                }}>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <GigsTable 
                    searchTerm={debounceSearchTerm} 
                    gigStatusIds={filterByGigStatusIds} 
                    complianceCheckFilter={complianceCheckFilter}
                />
            </Box>
            {!isLargeScreen && (
                <Link to={appPaths.gigs.create}>
                    <Fab
                        color="primary"
                        aria-label="create-gig"
                        sx={{
                            position: "fixed",
                            bottom: theme.spacing(10),
                            right: theme.spacing(4),
                        }}
                    >
                        <AddIcon fontSize="large" />
                    </Fab>
                </Link>
            )}
        </>
    );
};

export default GigsTableWithSearch;