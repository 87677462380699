import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, SxProps, Theme } from "@mui/material";

export type FormTextInputProps = {
    name: string
    label?: string
    disabled?: boolean
    required?: boolean
    multiline?: boolean
    type?: "text" | "email" | "number" | "tel"
    variant?: "outlined" | "standard"
    sx?: SxProps<Theme>
    placeholder?: string
};

const FormTextInput = React.forwardRef<HTMLInputElement, FormTextInputProps>(({
    name,
    label,
    disabled,
    required,
    multiline,
    type,
    variant = "outlined",
    sx,
    placeholder,
    ...inputProps
}, ref) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const errorMessage = errors[name] ? errors[name].message : "";
    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    {...inputProps}
                    fullWidth
                    multiline={multiline}
                    error={!!error}
                    helperText={errorMessage}
                    label={label}
                    disabled={disabled}
                    required={required}
                    type={type}
                    variant={variant}
                    sx={sx}
                    ref={ref}
                    placeholder={placeholder}
                />
            )}
        />
    );
});

export default FormTextInput;
