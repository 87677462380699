import { ApiClientStatisticsDto } from "../../api/models/dashboardStatistics/clientStatistics";
import { useOrganizationConfig } from "../../api/organization";
import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";

export type DashboardClientSectionProps = {
    statistics?: ApiClientStatisticsDto
}

const DashboardClientsSection = ({
    statistics
}: DashboardClientSectionProps) => {
    const { giggedClientTerminologyPlural, giggedClientTerminology } = useOrganizationConfig();

    return (
        <DashboardSection
            title={giggedClientTerminologyPlural}
        >
            <div className="flex flex-col space-y-2 w-full">
                <DashboardCard
                    title={`Total ${giggedClientTerminologyPlural.toLowerCase()}`}
                    statistic={statistics?.createdTotal}
                    tooltip={dashboardOverviewTooltips.total(giggedClientTerminologyPlural)}
                />
                <DashboardSubSection
                    title={`New ${giggedClientTerminologyPlural.toLowerCase()}`}
                    tooltip={dashboardOverviewTooltips.created(giggedClientTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={`${statistics?.createdLast7Days || 0} (+${statistics?.percentageIncreaseLast7Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={`${statistics?.createdLast14Days || 0} (+${statistics?.percentageIncreaseLast14Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={`${statistics?.createdLast30Days || 0} (+${statistics?.percentageIncreaseLast30Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={`${statistics?.createdLast3Months || 0} (+${statistics?.percentageIncreaseLast3Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={`${statistics?.createdLast6Months || 0} (+${statistics?.percentageIncreaseLast6Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={`${statistics?.createdLast9Months || 0} (+${statistics?.percentageIncreaseLast9Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={`${statistics?.createdLast12Months || 0} (+${statistics?.percentageIncreaseLast12Months || 0}%)`}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title={`Active ${giggedClientTerminologyPlural.toLowerCase()}`}
                    tooltip={dashboardOverviewTooltips.lastActive(giggedClientTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={statistics?.activeLast7Days || 0}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={statistics?.activeLast14Days || 0}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={statistics?.activeLast30Days || 0}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={statistics?.activeLast3Months || 0}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={statistics?.activeLast6Months || 0}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={statistics?.activeLast9Months || 0}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={statistics?.activeLast12Months || 0}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title={`${giggedClientTerminology} users`}
                    tooltip={dashboardOverviewTooltips.clientUsers(giggedClientTerminologyPlural, giggedClientTerminology)}
                >
                    {statistics?.giggedClientsUsersByCount && statistics?.giggedClientsUsersByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics?.giggedClientsUsersByCount.map(client => (
                                <DashboardCard
                                    key={client.giggedClientsCount}
                                    title={`${giggedClientTerminology} users: ${client.usersCount}`}
                                    statistic={client.giggedClientsCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
                <DashboardSubSection
                    title="Locations"
                    tooltip={dashboardOverviewTooltips.locations(giggedClientTerminologyPlural)}
                >
                    {statistics?.giggedClientsLocationsByCount && statistics?.giggedClientsLocationsByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics?.giggedClientsLocationsByCount.map(location => (
                                <DashboardCard
                                    key={location.locationName}
                                    title={location.locationName}
                                    statistic={location.locationCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardClientsSection;