import { talentWithdrawalStatusOptions } from "../../api/models/talentWithdrawal";
import TableChipCell from "../Table/TableChipCell";

export type TalentWithdrawalStatusCellProps = {
    talentWithdrawalStatusId: number
}

const TalentWithdrawalStatusCell = ({
    talentWithdrawalStatusId,
}: TalentWithdrawalStatusCellProps) => {
    const option = talentWithdrawalStatusOptions.find(_ => _.value === talentWithdrawalStatusId);

    return (
        <TableChipCell labels={[option?.label || ""]} />
    );
};

export default TalentWithdrawalStatusCell;