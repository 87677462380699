import { useProposalMilestone } from "../api/proposalMilestone";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import ProposalMilestoneViewPage from "../components/ProposalMilestoneView/ProposalMilestoneViewPage";
import { useProposalMilestoneId } from "../Routes";

const ProposalMilestonePage = () => {
    const proposalMilestoneId = useProposalMilestoneId();
    const { proposalMilestone, isLoading } = useProposalMilestone(proposalMilestoneId);

    if (!proposalMilestone || isLoading) return <Loader />;

    return (
        <FormContainer>
            <ProposalMilestoneViewPage
                proposalMilestone={proposalMilestone}
            />
        </FormContainer>
    );
};

export default ProposalMilestonePage;