import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Popover, Typography } from "@mui/material";

export type CopyIconProps = {
    title: string,
    textToCopy: string,
    notificationText?: string
}

const CopyIcon = ({
    title,
    textToCopy,
    notificationText = "Copied to clipboard"
}: CopyIconProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigator.clipboard.writeText(textToCopy);
        setAnchorEl(event.currentTarget);
        setTimeout(() => setAnchorEl(null), 2000); // Auto close after 2 seconds
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip title={title} placement="top" sx={{ backgroundColor: "transparent" }}>
                <IconButton onClick={handleClick}>
                    <ContentCopyIcon sx={{ color: "black", paddingBottom: 0 }} fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Typography sx={{ p: 1, fontSize: "0.875rem" }}>{notificationText}</Typography>
            </Popover>
        </>
    );
};

export default CopyIcon;