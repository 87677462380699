import { useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { Box, useTheme, MenuItem, Select } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import FormTextInput from "../FormTextInput";
import ApiError from "../../api/common/apiError";
import FormErrors from "../FormErrors";
import ConfirmDialog from "../ConfirmDialogBox";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import FormDateInput from "../FormDateInput";
import FormTextAreaInput from "../FormTextAreaInput";
import ToggleButton from "../ToggleButton";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { GigCreateFormValues, useGigCreateForm } from "./GigCreateFormValues";
import FormInputLabel from "../FormInputLabel";
import { GiggedClientInput } from "../GiggedClientDropdown";
import GigSkillsInput from "../GigSkillsInput";
import { useOrganizationConfig } from "../../api/organization";
import { gigStatuses, expectedDurationOptions, initialStageOptions } from "../../api/models/gig";
import { addDays, differenceInBusinessDays } from "date-fns";

type GigCreateFormProps = {
    onSubmit: (values: GigCreateFormValues) => Promise<GigApiFetcherResponse<string>>
    isSubmitting: boolean
}

const determineExpectedDurationId = (numberOfDays: number) => {
    if (numberOfDays < 7) return 0; // Less than a week
    else if (numberOfDays < 30) return 1; // Less than a month
    else if (numberOfDays <= 30) return 2; // 1 month
    else if (numberOfDays <= 90) return 3; // 2-3 months
    else if (numberOfDays <= 180) return 4; // 3-6 months
    else return 5; // More than 6 months
};

const GigCreateForm = ({
    onSubmit,
    isSubmitting
}: GigCreateFormProps) => {
    const { giggedClientTerminology, gigTerminology, organizationConfig } = useOrganizationConfig();
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.gigs.index);
        }
    });

    const methods = useGigCreateForm();
    const { formState, formState: { isDirty, errors, submitCount }, watch, setValue, trigger, clearErrors, control, handleSubmit } = methods;

    const { skills, isTimeAndMaterial, startDate, deadlineDate, dailyRate, numberOfDays } = watch();

    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigCreateFormValues, "skills">({
        control: control,
        name: "skills",
        keyName: "id",
    });

    useEffect(() => {    
        if (isTimeAndMaterial) {
            let shouldTriggerValidation = false;
    
            // Calculating number of days
            if (startDate && deadlineDate) {
                const newNumberOfDays = differenceInBusinessDays(new Date(deadlineDate), new Date(startDate)) + 1;
                setValue("numberOfDays", newNumberOfDays, { shouldValidate: true });
                setValue("expectedDurationId", determineExpectedDurationId(newNumberOfDays), { shouldValidate: true });
                shouldTriggerValidation = true;
            }
    
            // Calculating budget
            if (dailyRate && numberOfDays) {
                setValue("budget", dailyRate * numberOfDays, { shouldValidate: true });
                shouldTriggerValidation = true;
            }
    
            // Optionally, trigger validation for specific fields if needed
            if (shouldTriggerValidation) {
                trigger(["numberOfDays", "dailyRate", "budget"]);
            }
        } else {
            // Resetting fields when isTimeAndMaterial is false
            setValue("numberOfDays", null);
            setValue("dailyRate", null);
            setValue("budget", null);
            clearErrors(["numberOfDays", "dailyRate", "budget"]);
        }
    }, [isTimeAndMaterial, startDate, deadlineDate, dailyRate, numberOfDays]);

    const handleGigSubmit = async (values: GigCreateFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit({
            ...values,
            gigStatusId: gigStatuses.posted
        });

        if (!response.success) setSubmissionError(response.error);

        return response;
    };

    const handleSaveAsDraft = async (values: GigCreateFormValues) => {
        setSubmissionError(undefined);

        const isValid = await methods.trigger();

        if (isValid) {
            const response = await onSubmit({
                ...values,
                gigStatusId: gigStatuses.draft
            });

            if (!response.success) setSubmissionError(response.error);

            return response;
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.gigs.index);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleGigSubmit)}
                    noValidate
                >
                    <FormCard onClose={handleCloseButton} title={`Create ${gigTerminology}`}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: theme.spacing(2),

                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel required>{`${gigTerminology} title`}</FormInputLabel>
                                <FormTextInput
                                    required
                                    name="title"
                                    disabled={isSubmitting}
                                />
                            </Box>
                        </Box>
                        <FormTextAreaInput 
                            name="description" 
                            label={`${gigTerminology} description`} 
                            disabled={isSubmitting} 
                            sx={{ marginBottom: theme.spacing(2) }}
                            required 
                        />
                        {organizationConfig?.isPaymentsEnabled && ( 
                            <div className="mb-4">
                                <Controller
                                    name="isTimeAndMaterial"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleButton
                                            label={isTimeAndMaterial ? "Time and material" : "Outcome based"}
                                            onChange={onChange}
                                            checked={value}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        <Box className="space-y-4 mb-4">
                            <Box className="md:space-x-8 space-y-4 md:space-y-0 flex flex-col md:flex-row">
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="startDate"
                                                label="Start date"
                                                value={value}
                                                onChange={onChange}
                                                minDate={new Date()}
                                                error={formState.errors.startDate}
                                                required
                                                shouldDisableWeekends={isTimeAndMaterial}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="deadlineDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="deadlineDate"
                                                label={isTimeAndMaterial ? "Deadline" : "Deadline (optional)"}
                                                value={value}
                                                onChange={onChange}
                                                error={formState.errors.deadlineDate}
                                                minDate={addDays(methods.watch().startDate as Date, 1)}
                                                required={isTimeAndMaterial}
                                                shouldDisableWeekends={isTimeAndMaterial}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box className="md:space-x-8 space-y-4 md:space-y-0 flex flex-col md:flex-row">
                                <Box className="flex flex-col flex-1 space-y-2">
                                    {isTimeAndMaterial ? (
                                        <>
                                            <FormInputLabel required>Number of days</FormInputLabel>
                                            <FormTextInput
                                                multiline
                                                required
                                                type="number"
                                                name="numberOfDays"
                                                disabled
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FormInputLabel required>Duration</FormInputLabel>
                                            <Controller
                                                name="expectedDurationId"
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        variant="outlined"
                                                        color="secondary"
                                                    >
                                                        {expectedDurationOptions.map(option => (
                                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </>
                                    )}
                                </Box>
                                <Box className="flex flex-col flex-1 space-y-2">
                                    <FormInputLabel required>{`Stage of the ${gigTerminology.toLowerCase()}`}</FormInputLabel>
                                    <Controller
                                        name="initialStageId"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                {initialStageOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Box>                        
                        {organizationConfig?.isPaymentsEnabled && (
                            <>
                                <Box className="space-y-2">
                                    {isTimeAndMaterial ? (
                                        <div className="flex space-x-4">
                                            <div className="w-1/2 space-y-2">
                                                <FormInputLabel required>{`${gigTerminology} daily rate (£)`}</FormInputLabel>
                                                <FormTextInput
                                                    multiline
                                                    required
                                                    type="number"
                                                    name="dailyRate"
                                                />
                                            </div>
                                            <div className="w-1/2 space-y-2">
                                                <FormInputLabel required>{`${gigTerminology} budget (£)`}</FormInputLabel>
                                                <FormTextInput
                                                    multiline
                                                    required
                                                    type="number"
                                                    name="budget"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <FormInputLabel required>{`${gigTerminology} budget (£)`}</FormInputLabel>
                                            <FormTextInput
                                                multiline
                                                required
                                                type="number"
                                                name="budget"
                                            />
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                        <Box className="space-y-4">
                            <Box className="space-y-2 flex flex-col mt-4">
                                <FormInputLabel required>{giggedClientTerminology}</FormInputLabel>
                                <Controller
                                    name="giggedClientId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <GiggedClientInput
                                            error={errors.giggedClientId}
                                            onChange={onChange}
                                            value={value}
                                            isSubmitting={isSubmitting}
                                        />
                                    )}
                                />
                            </Box>
                            <GigSkillsInput
                                addSkill={appendSkill}
                                removeSkill={removeSkill}
                                skills={skills || []}
                                error={errors.skills}
                                showError={submitCount > 0}
                                required
                            />
                            <Controller
                                name="isCompletedRemotely"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Can be completed remotely?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            <Controller
                                name="isComplianceCheckRequired"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Compliance check required?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "2rem", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                        }>
                            <LoadingButton
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                type="submit"
                                color="secondary"
                                onClick={() => handleSaveAsDraft(methods.getValues())}
                            >
                                Save as draft
                            </LoadingButton>

                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Create gig
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialog
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default GigCreateForm;