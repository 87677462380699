import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, SxProps, Theme, Box, Alert } from "@mui/material";

type DialogBoxProps = {
    title?: string
    isOpen: boolean
    contentText?: string
    warningText?: string
    children?: React.ReactNode
    buttons?: JSX.Element
    sx?: SxProps<Theme>
    fullWidth?: boolean
    maxWidth?: "sm" | "md" | "lg"
    onClose?: () => void
}

const DialogBox = ({
    title,
    isOpen,
    contentText,
    warningText,
    children,
    buttons,
    sx,
    fullWidth,
    maxWidth,
    onClose
}: DialogBoxProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth}>
            <Box sx={{ padding: 1 }}>
                <DialogTitle  className="!font-bold">{title}</DialogTitle>
                <DialogContent sx={sx} className="space-y-4">
                    {contentText && (
                        <DialogContentText sx={{ color: "#000000" }}>
                            {contentText}
                        </DialogContentText>
                    )}
                    {warningText && (
                        <Alert severity="warning">{warningText}</Alert>
                    )}
                    {children}
                    {buttons && (
                        <DialogActions>
                            {buttons}
                        </DialogActions>
                    )}
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default DialogBox;