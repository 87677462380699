import { ApiProposalStatisticsDto } from "../../api/models/dashboardStatistics/proposalStatistics";
import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";

export type DashboardProposalsSectionProps = {
    statistics?: ApiProposalStatisticsDto
}

const DashboardProposalsSection = ({
    statistics
}: DashboardProposalsSectionProps) => {
    return (
        <DashboardSection
            title="Proposals"
        >
            <div className="flex flex-col space-y-2 w-full">
                <DashboardCard
                    title="Total proposals"
                    statistic={statistics?.proposalsCount}
                    tooltip={dashboardOverviewTooltips.proposalsTotal}
                />
                <DashboardSubSection
                    title="Proposals by status"
                    tooltip={dashboardOverviewTooltips.proposalsByStatus}
                >
                    {statistics?.proposalStatusByCount && statistics?.proposalStatusByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics.proposalStatusByCount.map(proposal => (
                                <DashboardCard
                                    key={proposal.proposalStatusName}
                                    title={proposal.proposalStatusName}
                                    statistic={proposal.proposalStatusCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardProposalsSection;