import { IconButton, TableCell } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import { useGigs } from "../../api/gigs";
import formatDateOnlyString from "../../utils/formatDateString";
import Loader from "../Loader";
import Table from "../Table";
import TableCard from "../TableCard";
import GigStatusCell from "./GigStatusCell";
import { useAppPaths } from "../../Routes";
import FilteredLinkCell from "../FilteredLinkCell";
import { useOrganizationConfig } from "../../api/organization";
import formatDateTimeString from "../../utils/formatDateTimeString";
import TableChipCell from "../Table/TableChipCell";
import { TableHeaderConfig } from "../Table/Table";
import { gigStatuses } from "../../api/models/gig";

export type GigsTableProps = {
    searchTerm?: string,
    gigStatusIds: number[],
    complianceCheckFilter?: boolean
};

const GigsTable = ({
    searchTerm,
    gigStatusIds = [],
    complianceCheckFilter
}: GigsTableProps) => {
    const appPaths = useAppPaths();
    const gigs = useGigs();
    const { giggedClientTerminology, talentTerminology, organizationConfig, gigDashboardDomain } = useOrganizationConfig();
    const setComplianceCheckFilter = gigs.setComplianceCheckFilter;

    const handleOrderByChange = (property: string) => {
        const isAsc = gigs.orderBy === property && gigs.sortOrder === "asc";
        gigs.setSortOrder(isAsc ? "desc" : "asc");
        gigs.setOrderBy(property);
    };

    useEffect(() => {
        gigs.setSearchTerm(searchTerm);
    }, [gigs.setSearchTerm, searchTerm]);

    useEffect(() => {
        gigs.setGigStatusIds(gigStatusIds);
    }, [gigs.setGigStatusIds, gigStatusIds]);

    useEffect(() => {
        setComplianceCheckFilter(complianceCheckFilter);
    }, [setComplianceCheckFilter, complianceCheckFilter]);

    const headers: TableHeaderConfig[] = [
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "Title",
            label: "Title",
            isSortable: true
        },
        {
            key: "GiggedClientName",
            label: giggedClientTerminology,
            isSortable: true,
        },        
    ];

    if (organizationConfig?.isBadgesEnabled && organizationConfig.name === "Gigged.AI") {
        headers.push(
            {
                label: "Client Verification",
                isSortable: false,
            },
        );
    }

    headers.push(
        {
            label: `Hired ${talentTerminology.toLowerCase()}`,
            isSortable: false,
        },
        {
            key: "CreatedAt",
            label: "Date created",
            isSortable: true,
        },
        {
            key: "LastUpdatedAt",
            label: "Last updated",
            isSortable: true,
        },
        {
            key: "GigStatusId",
            label: "Status",
            isSortable: true,
        },
        {
            key: "ProposalsCount",
            label: "Proposals",
            isSortable: true,
        },
    );

    if (organizationConfig?.isPaymentsEnabled) {
        headers.push(
            {
                label: "Hired Proposal Amount",
                isSortable: false,
            },
            {
                label: "Compliance check required",
                isSortable: false
            }
        );
    }

    headers.push(
        {
            label: "View live post",
            isSortable: false,
        },
        {
            label: "",
            isSortable: false,
            center: true,
        });


    if (gigs.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={gigs.gigs}
                renderRow={gig => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{gig.id}</TableCell>
                        <TableCell sx={{ 
                            whiteSpace: "nowrap",
                            backgroundImage: gig.isWorksome ? "url('/worksome-icon-40x40.svg')" : "none",
                            backgroundRepeat: "no-repeat", 
                            backgroundPosition: "right 2px center",
                            backgroundSize: "30px 30px",
                            minWidth: "200px"
                        }}>
                            {gig.title}
                        </TableCell>
                        <TableCell sx={{ 
                            whiteSpace: "nowrap",
                            backgroundImage: gig.isWorksome ? "url('/worksome-icon-40x40.svg')" : "none",
                            backgroundRepeat: "no-repeat", 
                            backgroundPosition: "right 2px center",
                            backgroundSize: "30px 30px",
                            minWidth: "200px"
                        }}>
                            {gig.giggedClientName}
                        </TableCell>
                        {organizationConfig?.isBadgesEnabled && organizationConfig.name === "Gigged.AI" && <TableChipCell labels={[gig.isGiggedClientVerified ? "Verified" : "Pending"]} />}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{gig.hiredTalentEmail || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(gig.createdAt)}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{gig.lastUpdatedAt ? formatDateTimeString(gig.lastUpdatedAt) : "-"}</TableCell>
                        <GigStatusCell gigStatusId={gig.gigStatusId} />
                        <FilteredLinkCell
                            count={gig.proposalsCount}
                            href={appPaths.gigs.proposals(gig.id)}
                        />
                        {organizationConfig?.isPaymentsEnabled && <TableCell>{gig.hiredProposalAmount || "-"}</TableCell>}
                        {organizationConfig?.isPaymentsEnabled && <TableChipCell labels={[gig.isComplianceCheckRequired ? "Yes" : ""]} />}
                        <TableCell sx={{ textAlign: "center" }}>
                            {gig.gigStatusId === gigStatuses.posted ? (
                                <a href={`https://${gigDashboardDomain}/find-a-gig/${gig.id}`} target="_blank">
                                    <VisibilityIcon color="secondary" />
                                </a>
                            ) : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.gigs.view(gig.id)}>
                                <IconButton aria-label="view" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={gigs.setPageIndex}
                setPageSize={gigs.setPageSize}
                orderBy={gigs.orderBy}
                sortOrder={gigs.sortOrder}
                pageSize={gigs.pageSize}
                pageIndex={gigs.pageIndex}
                totalCount={gigs.totalCount}
                isLoading={gigs.isLoading}
            />
        </TableCard>
    );
};

export default GigsTable;