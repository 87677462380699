import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import defaultIfUndefined from "../../utils/defaultIfUndefined";
import { useOrganizationConfig } from "../../api/organization";
import { hasNoMoreThan2DecimalPlaces } from "../../utils/yup-validators/hasNoMoreThan2DecimalPlaces";

export type GigEditFormValues = {
    title: string
    expectedDurationId: number
    initialStageId: number
    description: string
    startDate: Date | null
    deadlineDate: Date | null
    skills: { id: string, name: string }[],
    budget: number | null,
    isCompletedRemotely: boolean
    isComplianceCheckRequired: boolean
    gigStatusId: number,    
    isTimeAndMaterial: boolean
    dailyRate: number | null
    numberOfDays: number | null
};

export const useEditGigForm = (initialValues?: GigEditFormValues) => {
    const schema = gigEditValidationSchema();
    
    return useForm<GigEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onTouched",
        defaultValues: {
            title: initialValues?.title || "",
            expectedDurationId: defaultIfUndefined(initialValues?.expectedDurationId, -1),
            startDate: initialValues?.startDate || null,
            deadlineDate: initialValues?.deadlineDate || null,
            description: initialValues?.description || "",
            initialStageId: defaultIfUndefined(initialValues?.initialStageId, -1),
            skills: initialValues?.skills || [],
            budget: initialValues?.budget || null,
            isCompletedRemotely: initialValues?.isCompletedRemotely || true,
            isComplianceCheckRequired: initialValues?.isComplianceCheckRequired || false,
            isTimeAndMaterial: initialValues?.isTimeAndMaterial || false,
            dailyRate: initialValues?.dailyRate || null,
            numberOfDays: initialValues?.numberOfDays || null
        },
    });
};

const gigEditValidationSchema = () => {
    const { gigTerminology, organizationConfig } = useOrganizationConfig();

    return yup
        .object({
            title: yup.string().required(`${gigTerminology} title is required.`),
            description: yup.string().required(`${gigTerminology} description is required.`),
            startDate: yup.date()
                .required("Start date is required.")
                .min(new Date(), "Start date must be later than today.")
                .nullable()
                .typeError("Start date must be a valid date."),
            deadlineDate: yup.date().when("isTimeAndMaterial", {
                is: true,
                then: yup.date()
                    .test("deadline-greater-than-start", (value, context) => {
                        if (!value) {
                            return context.createError({ message: "Deadline is required." });
                        }
                    
                        if (value <= context.parent.startDate) {
                            return context.createError({ message: "Deadline must be after start." });
                        }
                    
                        return true;
                    })
                    .typeError("Deadline must be a valid date.")
                    .required("Deadline is required."),
                otherwise: yup.date()
                    .test("deadline-greater-than-start", (value, context) => {
                        if (!value) return true;
                    
                        if (value <= context.parent.startDate) {
                            return context.createError({ message: "Deadline must be after start." });
                        }
                    
                        return true;
                    })
                    .nullable()
                    .optional()
                    .typeError("Deadline must be a valid date."),
            }),
            skills: yup
                .array()
                .length(3, "You must select 3 skills")
                .of(
                    yup.object().shape({
                        id: yup.string(),
                        name: yup.string(),
                    }),
                ),
            budget: organizationConfig?.isPaymentsEnabled
                ? yup.number()
                    .typeError("Budget is required.")
                    .required("Budget is required.")
                    .min(0.01, "Budget must be greater than £0.")
                    .max(1_000_000.01, "Budget cannot be greater than £1,000,000.")
                    .test(hasNoMoreThan2DecimalPlaces)
                : yup.number(),
            expectedDurationId: yup.number().required().min(0, "Duration is required."),
            initialStageId: yup.number().required().min(0, "Current stage is required."),
            isCompletedRemotely: yup.boolean().required("This field is required."),
            isComplianceCheckRequired: yup.boolean().required("This field is required."),
            isTimeAndMaterial: yup.boolean(),
            dailyRate: yup.number().when("isTimeAndMaterial", {
                is: true, // Only apply the following rules if isTimeAndMaterial is true
                then: yup.number()
                    .typeError("Daily rate is required.")
                    .required("Daily rate is required.")
                    .min(0.01, "Daily rate must be greater than £0.")
                    .max(10_000.01, "Daily rate cannot be greater than £10,000.")
                    .test(hasNoMoreThan2DecimalPlaces),
                otherwise: yup.number().nullable(),
            }),
            numberOfDays: yup.number().when("isTimeAndMaterial", {
                is: true, // Only apply the following rules if isTimeAndMaterial is true
                then: yup.number()
                    .typeError("Number of days is required.")
                    .required("Number of days is required.")
                    .min(1, "Number of days must be at least 1."),
                otherwise: yup.number().nullable(),
            }),
        })
        .required();
};