import { useTalentPayoneerEmail } from "../api/talentPayoneerAccount";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import TalentPayoneerDetailsView from "../components/TalentPayoneerDetailsView";
import { useTalentId } from "../Routes";

const TalentPayoneerDetailsPage = () => {
    const talentId = useTalentId();
    const { talentPayoneerEmail, isLoading } = useTalentPayoneerEmail(talentId);

    if (isLoading || !talentPayoneerEmail) {
        return <Loader />;
    }

    return (
        <FormContainer>
            <TalentPayoneerDetailsView payoneerEmail={talentPayoneerEmail} />
        </FormContainer>
    );
};

export default TalentPayoneerDetailsPage;
